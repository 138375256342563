import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import i18n from "../../i18n";
import "./styles.scss";


class FooterComponent extends Component {
    render() {
        return (
            <footer className='footer'>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item md={3} xs={6}>
                            <p className="widget-title">{i18n.t('CrowdEstate')}</p>
                            <ul className="footer-links">
                                <li>{i18n.t('About')}</li>
                                <li>{i18n.t('Team')}</li>
                                <li>{i18n.t('Jobs')}</li>
                                <li>{i18n.t('Contact')}</li>
                            </ul>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <p className="widget-title">{i18n.t('Learn More')}</p>
                            <ul className="footer-links">
                                <li>{i18n.t('Howto')}</li>
                                <li>{i18n.t('FAQ')} / {i18n.t('help')}</li>
                                <li>{i18n.t('Affiliate')}</li>
                                <li>{i18n.t('Sell properties')}</li>
                            </ul>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <p className="widget-title">{i18n.t('More Information')}</p>
                            <ul className="footer-links">
                                <li>{i18n.t('Our Patners')}</li>
                                <li>{i18n.t('Blog')}</li>
                                <li>{i18n.t('Press')}</li>
                            </ul>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <ul className="social-links">
                                <li><FacebookIcon /></li>
                                <li><TwitterIcon /></li>
                                <li><InstagramIcon /></li>
                            </ul>
                            <ul className="footer-links">
                                <li>{i18n.t('Imprint')}</li>
                                <li>{i18n.t('Privacy Policy')}</li>
                                <li>{i18n.t('Terms of Service')}</li>
                            </ul>
                        </Grid>
                    </Grid>

                    <div className="footer-bottom">
                        <p>&copy; 2020 CrowdEstate {i18n.t('All rights reserved')}.</p>
                    </div>
                </Container>
            </footer>
        )
    }
}

export default FooterComponent;