import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import {
    Link, List, ListItem, ListItemText, Popper,
    Paper, Grow, MenuItem, MenuList, ClickAwayListener
} from "@material-ui/core"
// import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
// import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import PersonIcon from '@material-ui/icons/Person';
import { userActions } from '../../../_actions';
import i18n from "../../../i18n";
import "../styles.scss";

class HeaderLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropDownOpen: false,
            profile_data: {}
        }

        this.loggedUser = false;

        var tokenInfo = this.props.parseJwt();
        if (tokenInfo && tokenInfo.status) {
            this.loggedUser = tokenInfo.data;
        }
        this.anchorRef = createRef();
    }

    componentDidMount() {
        const { page } = this.props;
        if (page !== 'profile') this.props.getUserDetails();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.users && nextProps.users.profile_data && nextProps.users.profile_data !== prevState.profile_data) {
            return {
                profile_data: nextProps.users.profile_data
            };
        }

        return null;
    }


    logOut = () => {
        this.props.logOut();
    }

    handleToggle = () => {
        this.setState({ dropDownOpen: !this.state.dropDownOpen });
    }

    handleClose = (event) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({ dropDownOpen: false });
    }

    render() {
        const { profile_data } = this.state;
        const isProfileData = profile_data && Object.keys(profile_data).length;

        return (
            <List className="header-links">
                <ListItem className="nav-link">
                    <ListItemText>
                        <NavLink to="/marketplace" className="nav-text">{i18n.t('Marketplace')}</NavLink>
                    </ListItemText>
                </ListItem>
                {/* <ListItem className="nav-link">
                    <ListItemText className="nav-icon">
                        <MailOutlineOutlinedIcon />
                        <span className="alert-badge color1"></span>
                    </ListItemText>
                </ListItem>
                <ListItem className="nav-link">
                    <ListItemText className="nav-icon">
                        <NotificationsNoneOutlinedIcon />
                        <span className="alert-badge color2"></span>
                    </ListItemText>
                </ListItem> */}
                <ListItem ref={this.anchorRef}
                    className="nav-link"
                    aria-controls="menu-list-grow"
                    aria-haspopup="true" onClick={this.handleToggle}>
                    <div>
                        {isProfileData && profile_data.profile_image ?
                            <div><img alt="user-avtar" className="user-avtar-img" src={this.state.profile_data.profile_image} /> </div>
                            :
                            <div className="user-avtar-img"><PersonIcon /></div>
                        }
                    </div>
                    <span className="text-username">{this.loggedUser.first_name}</span>

                    <Popper className="custom-dropdown" open={this.state.dropDownOpen} anchorEl={this.anchorRef.current} transition disablePortal>
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                            >
                                <Paper id="menu-list-grow">
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList>
                                            <MenuItem>
                                                <NavLink to="/dashboard/profile"><span>{i18n.t('Profile')}</span></NavLink>
                                            </MenuItem>

                                             {/*
                                            <MenuItem>
                                                <NavLink to="/dashboard/profile/?settings"><span>{i18n.t('Settings')}</span></NavLink>
                                            </MenuItem>
                                            <MenuItem>
                                                <NavLink to="/dashboard/profile/?verification"><span>{i18n.t('Verification')}</span></NavLink>
                                            </MenuItem>
                                            <MenuItem>
                                                <Link underline="none"><span>{i18n.t('Invite friends')}</span></Link>
                                            </MenuItem> */}
                                            <MenuItem onClick={this.logOut}>
                                                <Link underline="none"><span>{i18n.t('Logout')}</span></Link>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ListItem>
            </List>
        );
    }
}


function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    parseJwt: userActions.parseJwt,
    getUserDetails: userActions.getUserDetails,
    logOut: userActions.logout
}

const connectedHeaderLinks = connect(mapState, actionCreators)(HeaderLinks);
export { connectedHeaderLinks as HeaderLinks };
