import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
import OverviewWidgetBlock1 from '../../components/DashboardComponent/OverviewWidgetBlock1';
import OverviewWidgetBlock2 from '../../components/DashboardComponent/OverviewWidgetBlock2';
// import PropertyMapWidget from '../../components/DashboardComponent/PropertyMapWidget';
import PropertyMapView from '../../components/PropertyMapView';
import {
    DashboardCommittedInvestmentsWidget, DashboardLatestInvestmentsWidget
} from '../../components/DashboardComponent/DashboardInvestmentsWidget';
import MapIcon from '@material-ui/icons/Map';
import { Grid, Button, Hidden } from "@material-ui/core";
import ListIcon from '@material-ui/icons/List';
import i18n from "../../i18n";
import { userActions, alertActions } from '../../_actions';
import "./styles.scss";
import CloseIcon from "@material-ui/icons/Close";
import { StickyContainer } from "react-sticky";
import FooterComponent from "../../components/FooterComponent";

let portfolioDataObj = {
    chartLabels: [null, null, null, null, null, null, null],
    datasets: [
        {
            borderWidth: 1.5,
            backgroundColor: "rgba(108, 210, 48, 0.1)",
            borderColor: "rgb(108, 210, 48)",
            data: [2, 1, 3, 3, 2, 3, 3, 0]
        }
    ]
};

let totalDividensDataObj = {
    chartLabels: [null, null, null, null, null, null, null],
    datasets: [
        {
            borderWidth: 1.5,
            backgroundColor: "rgba(254, 171, 43, 0.1)",
            borderColor: "rgb(254, 171, 43)",
            data: [1, 1, 2, 3, 3, 4, 4, 5]
        }
    ]
};

let profitLossDataObj = {
    chartLabels: [null, null, null, null, null, null, null],
    datasets: [
        {
            borderWidth: 1.5,
            backgroundColor: "rgba(254, 171, 43, 0.1)",
            borderColor: "rgb(254, 171, 43)",
            data: [1, 1, 2, 3, 3, 4, 4, 5]
        }
    ]
};

class DashboardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFooterOpen: false,
            is_map_visible: false,
            profile_data: {},
            AllCommittedInvestmentsData: [],
            AllLatestInvestmentsData: [],
            realised_profit_data: [],
            portfolioAmount: 0,
            portfolioChangeAmount: 0,
            portfolioChangePercentage: 0,
            sumCommitted: 0
        }
    }

    componentDidMount() {
        this.props.getUserInvestment();
        this.props.getUserLatestInvestment();
        this.props.getUserCommittedInvestment();
        this.props.getUserRealisedProfit();
        this.props.getUserRealisedProfitData();
        this.props.getUserUnrealisedProfit();
    }

    toggleView = () => {
        this.setState({ is_map_visible: !this.state.is_map_visible })
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        var update = {}
        const { latest_investment_data, investment_data, committed_investment_data, realised_profit, realised_profit_data, unrealised_profit } = nextProps.users;

        if (nextProps.users && nextProps.users.profile_data && nextProps.users.profile_data !== prevState.profile_data) {
            update = {
                ...update,
                profile_data: nextProps.users.profile_data
            };
        }

        // if (latest_investment_data && latest_investment_data.status === true) {
        //     update = {
        //         ...update,
        //         AllLatestInvestmentsData: latest_investment_data.data
        //     }
        // }

        if (realised_profit_data && realised_profit_data.status === true) {
            update = {
                ...update,
                realised_profit_data: realised_profit_data.data
            }
        }

        if (investment_data && investment_data.status === true) {
            update = {
                ...update,
                AllLatestInvestmentsData: investment_data.data
            }
        }


        if (committed_investment_data && committed_investment_data.status === true) {

            var sumCommitted = 0;
            committed_investment_data.data.map(committedInvestment => {
                sumCommitted +=  committedInvestment.investment_amount;
            });
            update = {
                ...update,
                AllCommittedInvestmentsData: committed_investment_data.data,
                sumCommitted: sumCommitted,
            }
        }

        // if (realised_profit && realised_profit.status) {
        //     update = {
        //         ...update,
        //         portfolioAmount: realised_profit.data.portfolio_amount,
        //         portfolioChangeAmount: realised_profit.data.portfolio_change_amount,
        //         portfolioChangePercentage: ((realised_profit.data.portfolio_change_amount) / (realised_profit.data.portfolio_amount + realised_profit.data.portfolio_change_amount) * 100).toFixed(2)//realised_profit.data.portfolio_change_percentage,
        //     }
        // }

        if (unrealised_profit && unrealised_profit.status) {

            var pfAmountSum = unrealised_profit.data.portfolio_amount + sumCommitted;
            var pfChange = (unrealised_profit.data.portfolio_change_amount != 0 && pfAmountSum != 0) ? (((unrealised_profit.data.portfolio_change_amount) / ((pfAmountSum) + (unrealised_profit.data.portfolio_change_amount* -1))) * 100).toFixed(2) : 0;


            update = {
                ...update,
                portfolioAmount: pfAmountSum,
                portfolioChangeAmount: unrealised_profit.data.portfolio_change_amount,
                portfolioChangePercentage: pfChange,
            }
        }

        return update;
    }

    handleGetPropertyDetailFromInvestments = () => {
        const { AllCommittedInvestmentsData, AllLatestInvestmentsData } = this.state;
        var allProperties = [];

        if (AllCommittedInvestmentsData.length) {
            AllCommittedInvestmentsData.map(committedInvestment => {
                if (committedInvestment.hasOwnProperty('property_master')) {
                    allProperties = [...allProperties, committedInvestment.property_master]


                }
                return null;
            })
        }



        if (AllLatestInvestmentsData.length) {
            AllLatestInvestmentsData.map(investment => {
                if (investment.hasOwnProperty('property_master')) {
                    allProperties = [...allProperties, investment.property_master]
                }
                return null;
            })
        }
        return allProperties;
    }

    toggleFooter = () => {
        this.setState({ isFooterOpen: !this.state.isFooterOpen })
    }

    render() {
        const { profile_data, AllCommittedInvestmentsData, AllLatestInvestmentsData } = this.state;
        const allProperties = this.handleGetPropertyDetailFromInvestments();

        return (
            <div className="dashboard-content">
                <NavBarComponent pageName="dashboard" />

                <main className="main-content">
                    <Grid container>
                        <Grid item md={7} xs={12}>
                            <p className="block-title">{i18n.t('Overview')}</p>
                            <Grid container>
                                <Grid item md={8} sm={12} xs={12}>
                                    <OverviewWidgetBlock1
                                        chartData={portfolioDataObj.datasets}
                                        chartLabels={portfolioDataObj.chartLabels}
                                        type={1}
                                        value={this.state.portfolioAmount}
                                        percentage={this.state.portfolioChangePercentage}
                                        block_text={i18n.t('Portfolio value')}
                                        percentage_value={this.state.portfolioChangeAmount} />
                                </Grid>
                                <Grid style={{ display: 'none' }} item md={8} sm={12} xs={12}>
                                    <OverviewWidgetBlock1
                                        chartData={totalDividensDataObj.datasets}
                                        chartLabels={totalDividensDataObj.chartLabels}
                                        type={2}
                                        value={16500}
                                        percentage={2.4}
                                        block_text={i18n.t('Total Dividends')}
                                        block_text_2={i18n.t('Avg. Dividend')} />
                                </Grid>
                                {/* <Grid item md={6} sm={6} xs={12}>
                                    <OverviewWidgetBlock1
                                        chartData={profitLossDataObj.datasets}
                                        chartLabels={profitLossDataObj.chartLabels}
                                        type={3}
                                        value={4300}
                                        percentage={1.4}
                                        block_text={i18n.t('Profit / Loss from trading')} />
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <OverviewWidgetBlock2 user_profile={profile_data} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Hidden xsDown>
                            <Grid className="investments-widget" item md={6} xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <p className="block-title">{i18n.t('Committed Investments')} </p>
                                        <DashboardCommittedInvestmentsWidget committedInvestments={AllCommittedInvestmentsData} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <p className="block-title">{i18n.t('Latest Investments')} </p>
                                        <DashboardLatestInvestmentsWidget latestInvestments={AllLatestInvestmentsData} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden>

                        {
                            !this.state.is_map_visible &&
                            <Hidden smUp>
                                <Grid className="investments-widget" item md={6} xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <p className="block-title">{i18n.t('Committed Investments')} </p>
                                            <DashboardCommittedInvestmentsWidget committedInvestments={AllCommittedInvestmentsData} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <p className="block-title">{i18n.t('Latest Investments')} </p>
                                            <DashboardLatestInvestmentsWidget latestInvestments={AllLatestInvestmentsData} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        }


                        <Grid item md={6} xs={12}>

                            <Hidden xsDown>
                                <p className="block-title">{i18n.t('Investment Locations')}</p>
                                <PropertyMapView properties={allProperties} />
                            </Hidden>

                            {
                                this.state.is_map_visible &&
                                <Hidden smUp>
                                    <p className="block-title">{i18n.t('Investment Locations')}</p>
                                    <PropertyMapView properties={allProperties} />
                                </Hidden>
                            }
                        </Grid>
                    </Grid>

                    <Button variant="contained" className="btn-toggle-view" onClick={this.toggleView}>
                        {!this.state.is_map_visible ? <span>{i18n.t('Map')} <MapIcon /></span> : <span>{i18n.t('List')} <ListIcon /></span>}
                    </Button>
                    {
                        this.state.isFooterOpen
                            ? <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}><CloseIcon /> {i18n.t('Close')}</Button>
                            : <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}>{i18n.t('GTC, Privacy policy & more')}</Button>
                    }
                </main>
                {
                    this.state.isFooterOpen ?
                        <div className="footer-block">
                            <FooterComponent />
                        </div>
                        : null
                }
            </div>
        )
    }
}


function mapState(state) {
    const { users } = state;
    return { users };
}

const actionCreators = {
    getUserInvestment: userActions.getUserInvestment,
    getUserLatestInvestment: userActions.getUserLatestInvestment,
    getUserCommittedInvestment: userActions.getUserCommittedInvestment,
    getUserRealisedProfit: userActions.getUserRealisedProfit,
    getUserRealisedProfitData: userActions.getUserRealisedProfitData,
    clearAlerts: alertActions.clear,
    getUserUnrealisedProfit: userActions.getUserUnrealisedProfit
}

export default connect(mapState, actionCreators)(DashboardPage);
