import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { ValidatorForm, TextValidator, } from 'react-material-ui-form-validator';
import i18n from "../../../i18n";
import { userActions, alertActions } from '../../../_actions';
import NotificationBox from '../../../components/GeneralComponent/NotificationBox';
import "./styles.scss";

class PaymentSource extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                user_id: 0,
                bank_account_owner: '',
                iban_number: '',
                bic_number: '',
                iban_verification_document: '',
                update_form_type: "payment_source"
            },
            invalidIbanVerifyDocErrorMsg: ""
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { profile_data } = props.users;
        if (profile_data && profile_data.user_id !== state.formData.user_id) {
            return {
                formData: {
                    ...state.formData,
                    user_id: profile_data.user_id,
                    bank_account_owner: profile_data.bank_account_owner,
                    iban_number: profile_data.iban_number,
                    bic_number: profile_data.bic_number,
                    update_form_type: "payment_source"
                }
            }
        }
        return null;
    }


    handleChange = (event) => {
        var fieldName = event.target.name;
        var fieldValue = event.target.value;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [fieldName]: fieldValue
            }
        }));
    }

    uploadIbanVerificationDoc = (e) => {
        this.setState({ invalidIbanVerifyDocErrorMsg: "" })
        let file = e.target.files[0];
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                'iban_verification_document': file
            }
        }));
    }

    handlePaymentSourceUpdate = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                if (this.state.formData.iban_verification_document !== '') {
                    this.props.updateUserProfile(this.state.formData).then(value => {
                        setTimeout(() => this.props.clearAlerts(), 1000);
                    });
                }
            }
        })
    }

    submit = () => {
        if (this.state.formData.iban_verification_document === '') {
            this.setState({ invalidIbanVerifyDocErrorMsg: i18n.t('Iban number verification document is required!') })
        }
        this.form.submit();
    }



    render() {
        const { alert } = this.props;
        const { formData, invalidIbanVerifyDocErrorMsg } = this.state;

        return (
            <div className='payment-source-tab'>
                {alert.message && <NotificationBox open={true} variant={alert.type} message={alert.message} />}

                <p className="page-block-title">{i18n.t("Bank Account")}</p>

                <p className="page-description">{i18n.t('All repayments of your invested capitals and returns are made to the provided bank account.')}</p>

                <div className="user-profile-form">
                    <ValidatorForm
                        className='default-form'
                        onSubmit={this.handlePaymentSourceUpdate}
                        ref={(r) => { this.form = r; }}
                        autoComplete="off"
                        instantValidate
                    >
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <label className="form-label">{i18n.t("Bank account owner")} *</label>
                                <TextValidator
                                    placeholder={i18n.t('Enter first and last name or company name')}
                                    variant="outlined"
                                    fullWidth
                                    name="bank_account_owner"
                                    value={formData.bank_account_owner}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    validators={['required']}
                                    errorMessages={[i18n.t('Owner name is required!')]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <label className="form-label">{i18n.t("Enter Iban")} *</label>
                                <TextValidator
                                    placeholder={i18n.t("Enter Iban number")}
                                    variant="outlined"
                                    fullWidth
                                    name="iban_number"
                                    value={formData.iban_number}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    validators={['required']}
                                    errorMessages={[i18n.t('Iban number is is required!')]}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <label className="form-label">{i18n.t("Enter Bic")} *</label>
                                <TextValidator
                                    placeholder={i18n.t('Enter Bic number')}
                                    variant="outlined"
                                    fullWidth
                                    name="bic_number"
                                    value={formData.bic_number}
                                    onChange={this.handleChange}
                                    className="form-control"
                                    validators={['required']}
                                    errorMessages={[i18n.t('Bic number is required!')]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <p><strong>{i18n.t("Account verification")}</strong></p>
                                <p>{i18n.t("In order to avoid money laundering, our payment service provider is obliged to match the provided IBAN with your identity")}.</p>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <p>{i18n.t("Please either upload a bank statement, a bank transfer confirmation or a photo of your bank card on which your IBAN and name must be visible")}.</p>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <div className='custom-upload-button'>
                                    <Button className="btn btn-upload btn-secondary"><CloudUploadIcon /> <span className="btn-label">{i18n.t("Upload")}</span></Button>
                                    <input
                                        type='file'
                                        onChange={this.uploadIbanVerificationDoc}
                                        className="custom-file-input"
                                        accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,image/*"
                                    />
                                    {formData.iban_verification_document && (
                                        <p className="iban-document-name">{formData.iban_verification_document.name}</p>
                                    )}
                                    {invalidIbanVerifyDocErrorMsg !== '' && (
                                        <p className="iban-document-error">{invalidIbanVerifyDocErrorMsg}</p>
                                    )}
                                </div>
                            </Grid>
                        </Grid>

                        <div className="form-action">
                            <Button className="btn btn-secondary" onClick={this.submit} variant={"contained"}>{i18n.t('Update Settings')}</Button>
                            <Button className="btn" variant={"contained"}>{i18n.t('Cancel')}</Button>
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        )
    }
}


function mapState(state) {
    const { users, alert } = state;
    return { users, alert };
}

const actionCreators = {
    updateUserProfile: userActions.updateUserProfile,
    clearAlerts: alertActions.clear,
}

export default connect(mapState, actionCreators)(PaymentSource);
