import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, alertActions } from '../../../_actions';
import { Dialog, DialogContent, Card, CardContent, Grid, Button, FormControlLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import config from '../../../config.json';
import CheckboxValidatorElement from '../../../components/CheckboxValidatorElement';
import i18n from "../../../i18n";
import NotificationBox from '../../../components/GeneralComponent/NotificationBox';
import "./styles.scss";

const MySwal = withReactContent(Swal)

class TradeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeStep: 1,
            property: {},
            tncCheck: false,
            factsheetCheck: false,
            initialStepSet: false,
            riskCheck: false,
            order_details: {
                _property_id: 0,
                _order_reference_id: null,
                _user_reference_id: null,
                order_type: "Buy",
                quantity: 0,
                price: 0,
                amount: 0,
                fee: 0,
                fee_amount: 0,
                total_amount: 0,
                payment_method: "account balance",
                order_complete: false
            },
        }
    }


    componentDidMount() {
        ValidatorForm.addValidationRule('isTruthy', value => value);
    }
    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isTruthy');
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.property !== prevState.property) {
            return {
                ...prevState,
                property: nextProps.property,
            }
        }

        if (nextProps.orderDetails && nextProps.orderDetails !== prevState.order_details) {
            return {
                ...prevState,
                activeStep: (nextProps.orderDetails.initialStep && !prevState.initialStepSet) ? nextProps.orderDetails.initialStep : prevState.activeStep,
                initialStepSet: true,
                order_details: {
                    ...prevState.order_details,
                    _order_reference_id: nextProps.orderDetails._order_reference_id,
                    _user_reference_id: nextProps.orderDetails._user_reference_id,
                    _property_id: nextProps.orderDetails._property_id,
                    order_type: nextProps.orderDetails.order_type,
                    quantity: parseInt(nextProps.orderDetails.quantity),
                    price: parseFloat(nextProps.orderDetails.price),
                    amount: nextProps.orderDetails.amount,
                    fee: nextProps.orderDetails.fee,
                    fee_amount: nextProps.orderDetails.fee_amount,
                    total_amount: nextProps.orderDetails.total_amount,
                    order_complete: nextProps.orderDetails.order_complete
                }
            }
        }

        return null;
    }

    closeModal = () => {
        this.setState({ activeStep: 1 });
        this.props.handleClose();
    }

    goToNextStep = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.setState(prevState => ({
                    activeStep: prevState.activeStep + 1
                }));
            }
        })
    }

    handlePermisionChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked });
    };

    formatePrice = (val) => {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = this.numberWithCommas(val)
            return val;
        }
    }

    numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }


    verifyUserSharesDetails() {
        var propertyId = this.state.order_details._property_id;
        this.props.verifyUserShares(propertyId, this.state.order_details).then(userSharesVerificationRes => {
            if (userSharesVerificationRes && userSharesVerificationRes.status) {
                this.goToNextStep();
                this.processOrder();
            } else {
                MySwal.fire({
                    title: <div>{i18n.t('Error')}</div>,
                    icon: 'error',
                    html: <div>
                        <h4>{i18n.t(userSharesVerificationRes.message)}</h4>
                    </div>,
                })
            }
        })
    }


    processOrder = () => {
        this.props.addUserOrder(this.state.order_details).then(value => {
            this.props.resetOrderTransaction();
            this.setState(prevState => ({
                order_details: {
                    ...prevState.order_details,
                    _order_reference_id: null,
                    _user_reference_id: null,
                    order_type: "Buy",
                    quantity: 0,
                    price: 0,
                    amount: 0,
                    fee: 0,
                    fee_amount: 0,
                    total_amount: 0,
                    payment_method: "account balance",
                    order_complete: false
                },
            }))
            setTimeout(() => this.props.clearAlerts(), 2000);
        })
    }


    submit = () => {
        this.form.isFormValid(false).then((isValid) => {
            if (isValid) {
                this.verifyUserSharesDetails()
            }
        })
    }


    handleSubmit = () => { }


    render() {
        const { activeStep, property, order_details } = this.state;
        const { alert } = this.props;

        return (
            <Dialog className='trade-modal' onClose={this.closeModal} open={this.props.open}>
                <DialogContent>
                    {alert.message && <NotificationBox open={true} variant={alert.type} message={alert.message} />}
                    <Button className="btn-modal-close" onClick={this.closeModal}><CloseIcon /></Button>
                    <ValidatorForm
                        className='trade-buy-form'
                        onSubmit={this.handleSubmit}
                        ref={(r) => { this.form = r; }}
                        autoComplete="off"
                        instantValidate
                    >
                        <h2 className="modal-title">{i18n.t('Investment')} </h2>
                        {property && <div className="title-box">
                            <div>
                                {property.property_images && property.property_images.length > 0 && (
                                    <img src={property.property_images[0].image_path} className="property-img" alt="Property" />
                                )}
                            </div>
                            <div className="assets-box">
                                <p className="txt-asset-title">{property.title}</p>
                                <p className="txt-asset-symbol">{property.token_name}</p>
                            </div>
                        </div>}
                        {property && <div className="content-box">
                            {activeStep === 1 && (<div className="step-wizard block-1">
                                <div className="payment-method-box">
                                    <p className="title-text">{i18n.t('Payment method')}</p>

                                    <Grid container spacing={4}>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type active">
                                                <AccountBalanceIcon />
                                                <p>{i18n.t('Account balance')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t('Bank transfer')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t('Sofortüberweisung')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t('Direct debit')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t('Google pay')}</p>
                                            </Card>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <Card className="payment-type disabled">
                                                <div className="coming-soon-title">{i18n.t('Coming soon')}</div>
                                                <p>{i18n.t('Apple pay')}</p>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Button fullWidth className="btn-action" variant="contained" onClick={() => this.goToNextStep()}>{i18n.t('Next')}</Button>
                            </div>)}

                            {activeStep === 2 && (<div className="step-wizard block-2">
                                <Card className="card-box">
                                    <CardContent>
                                        {(order_details.order_type === "Buy" || order_details.order_type === "Kaufe") ?
                                            <p>{i18n.t("Review details of your investment")}</p>
                                            :
                                            <p>{i18n.t("Review of your sell order")}</p>
                                        }

                                        <table className="table details-table">
                                            <tbody>
                                                <tr>
                                                    <td>{i18n.t("Amount of shares")}</td>
                                                    <td>{this.formatePrice(order_details.quantity)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{i18n.t("Price per share")}</td>
                                                    <td>&euro; {this.formatePrice(order_details.price)}</td>
                                                </tr>
                                                <tr>
                                                {(order_details.order_type == "Buy" || order_details.order_type === "Kaufe") ?
                                                    <td>{i18n.t("Your Investment amount")}</td>
                                                :
                                                    <td>{i18n.t("Your sales price")}</td>
                                                }

                                                    <td>&euro; {this.formatePrice(order_details.amount)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{i18n.t("Fee")} {this.formatePrice(order_details.fee)} %</td>
                                                    <td>&euro; {this.formatePrice(order_details.fee_amount)}</td>
                                                </tr>
                                                <tr>
                                                {(order_details.order_type == "Buy"  || order_details.order_type === "Kaufe") ?
                                                    <td>{i18n.t("Total Amount")}</td>
                                                :
                                                    <td>{i18n.t("Sales Proceeds")}</td>
                                                }


                                                    <td>&euro;  {this.formatePrice(order_details.total_amount)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </CardContent>
                                </Card>

                                {(order_details.order_type == "Buy"  || order_details.order_type === "Kaufe") && (
                                <Card className="card-box">
                                    <CardContent>
                                        <p>{i18n.t("Contract Documents & Conditions")}</p>
                                        {(property && property.property_documents && property.property_documents.length > 0) && (
                                            <table className="table downloads-table">
                                                <tbody>
                                                    {property.property_documents.map((document, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td width="30"><SaveAltIcon /></td>
                                                                <td>
                                                                    <a href={`${config.apiUrl}/download/property/${document.file_path}`} download
                                                                    ><span className="doc-name">{document.description}</span></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>)}

                                        <div className="acceptance-box">
                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        onChange={this.handlePermisionChange('tncCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept Terms and Conditions to continue')]}
                                                        checked={this.state.tncCheck}
                                                        value={this.state.tncCheck}
                                                    />
                                                }
                                                label={<TnCLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.factsheetCheck}
                                                        onChange={this.handlePermisionChange('factsheetCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please accept contract / cancellation instruction to continue')]}
                                                        value={this.state.factsheetCheck}
                                                    />
                                                }
                                                label={<FactSheetLabel />}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <CheckboxValidatorElement
                                                        checked={this.state.riskCheck}
                                                        onChange={this.handlePermisionChange('riskCheck')}
                                                        validators={['isTruthy']}
                                                        errorMessages={[i18n.t('Please risk to continue')]}
                                                        value={this.state.riskCheck}
                                                    />
                                                }
                                                label={<RiskLabel />}
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                                )}

                                <Button fullWidth className="btn-action" variant="contained" onClick={() => this.submit()}>


                                {(order_details._user_reference_id != null && order_details._user_reference_id != '') ? //order_details.order_type === "Buy" &&
                                            i18n.t("Accept Offer Now")
                                            :
                                            i18n.t("Create Offer Now")
                                 }



                                    </Button>
                            </div>)}

                            {activeStep === 3 && (<div className="step-wizard block-3">
                                <Card className="card-box">
                                    <CardContent>
                                        <p className="txt-style-1">{i18n.t("Congratulations on your new invetment!")}</p>
                                        <p className="txt-style-2">{i18n.t("You can track all your investments your dashboard.")}</p>
                                    </CardContent>
                                </Card>

                                <Link to="/dashboard"><Button fullWidth className="btn-action" variant="contained">{i18n.t("Go to dashboard")}</Button></Link>
                            </div>)}
                        </div>}
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        );
    }
}

const TnCLabel = () => {
    return (
        <p>{i18n.t("I have read the")} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t("terms and conditions")}</a> {i18n.t("of ABCD1234 GmBH and accept them hereby.")}</p>
    )
}

const FactSheetLabel = () => {
    return (
        <p>{i18n.t("I am taking note of that")} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t("contract / cancellation")}</a> {i18n.t("instruction and the")} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t("Fact Sheet")}</a> {i18n.t("and accpet them.")}</p>
    )
}

const RiskLabel = () => {
    return (
        <p>{i18n.t("I am aware of the")} <a href="http://www.africau.edu/images/default/sample.pdf" target="_blank" rel="noopener noreferrer">{i18n.t("risk")}</a>.</p>
    )
}



function mapState(state) {
    const { alert } = state;
    return { alert };
}


const actionCreators = {
    addUserOrder: userActions.addUserOrder,
    verifyUserShares: userActions.verifyUserShares,
    clearAlerts: alertActions.clear,
}

export default connect(mapState, actionCreators)(TradeModal);
