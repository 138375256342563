import { generalConstants } from '../_constants';
import {userService} from "../_services";
import i18n from "i18next";

export const initialState = async () => {

  language = 'en';
  try {
  var userData = await userService.getUserDetails();

  var language = 'en';
  if (userData && userData.data.language) {
    language = userData.data.language
  }
}
catch(ex) {
  console.log(ex);
}



  return {
    locations: [],
    property: {},
    properties: [],
    languageCode: i18n.language.split("-")[0] || window.localStorage.i18nextLng.split("_")[0],
    property_invested_shares: {}
  }
}

export function general(state = initialState, action) {
  switch (action.type) {
    case generalConstants.GET_ALL_PROPERTIES_SUCCESS:
      return {
        // ...state,
        properties: action.properties
      };
    case generalConstants.GET_PROPERTIES_SUCCESS:
      return {
        // ...state,
        property: action.properties
      };
    case generalConstants.GET_LOCATION_SUCCESS:
      return {
        ...state,
        locations: action.locations
      };

    default:
      return state
  }
}
