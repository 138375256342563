import React, { Component } from 'react';
import { Grid, Link } from '@material-ui/core';
import Gallery from 'react-grid-gallery';
import i18n from "../../i18n";
import "./styles.scss";

class PropertyDescription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            property: [],
        }
        this.galleryRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.property !== state.property) {
            return {
                property: props.property
            }
        }
        return null;
    }

    handleDataTransformation = (property_images) => {
        return property_images.map((image_item) => {
            return {
                src: image_item.image_path,
                thumbnailWidth: 300,
                thumbnailHeight: 300,
                thumbnail: image_item.image_path
            }
        });
    }

    openGalleryModal = () => {
        this.props.onModalOpen();
    }

    closeGalleryModal = () => {
        this.props.onModalClose();
    }

    exploreGalleryModal = (event) => {
        this.galleryRef.current.openLightbox(0, event);
    }

    render() {
        const { property } = this.state;

        return (
            <div className='property-description-box'>
                {property && property.property_images && property.property_images.length > 0 && (<Grid container>
                    <Grid item md={12} xs={12}>
                        <Gallery
                            ref={this.galleryRef} 
                            margin={0}
                            images={this.handleDataTransformation(property.property_images)}
                            thumbnailImageComponent={ImageComponent}
                            enableImageSelection={false}
                            showLightboxThumbnails={true}
                            lightboxWillClose={this.closeGalleryModal}
                            lightboxWillOpen={this.openGalleryModal}
                        />
                    </Grid>
                </Grid>)}
                {property && property.property_images && property.property_images.length > 0 && (<Link className="show-all-photos" onClick={this.exploreGalleryModal}>{i18n.t('property_detail_photos__explore_all')} ({property.property_images.length}) {i18n.t('photos')} ></Link>)}
            </div>
        )
    }
}

class ImageComponent extends React.Component {
    render() {
        return <img alt="Property" className="property-image" {...this.props.imageProps} />;
    }
}

export default PropertyDescription;
