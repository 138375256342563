import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { userActions } from '../../_actions';
import {
    Button, List, ListItem, ListItemText, Link,
    Popper, Paper, Grow, MenuItem, MenuList, ClickAwayListener
} from '@material-ui/core';
import i18n from "../../i18n";
import PersonIcon from "@material-ui/icons/Person";
import "./styles.scss";

class HeaderLinks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropDownOpen: false,
            profile_data: {}
        }

        this.loggedUser = false;
        var tokenInfo = this.props.parseJwt();
        if (tokenInfo && tokenInfo.status) {
            this.loggedUser = tokenInfo.data;
            this.props.getUserDetails();
        }
        this.anchorRef = createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var update = {}
        if (nextProps.users && nextProps.users.profile_data && nextProps.users.profile_data !== prevState.profile_data) {
            update = {
                profile_data: nextProps.users.profile_data
            };
        }

        return update;
    }

    logOut = () => {
        this.props.logOut();
    }

    handleToggle = () => {
        this.setState({ dropDownOpen: !this.state.dropDownOpen });
    }

    handleClose = (event) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({ dropDownOpen: false });
    }


    render() {
        return (
            <List className="header-links">
                <ListItem>
                    <ListItemText>
                        <NavLink to="/marketplace">{i18n.t('Marketplace')}</NavLink>
                    </ListItemText>
                </ListItem>
                {
                    this.loggedUser ? (
                        <>
                            <ListItem>
                                <ListItemText>
                                    <NavLink to="/dashboard">{i18n.t('Dashboard')}</NavLink>
                                </ListItemText>
                            </ListItem>
                            <ListItem ref={this.anchorRef}
                                className="user-avtar"
                                aria-controls="menu-list-grow"
                                aria-haspopup="true" onClick={this.handleToggle}>
                                <div>
                                    {this.state.profile_data && this.state.profile_data.profile_image ?
                                        <div><img alt="user-avtar" className="user-avtar-img" src={this.state.profile_data.profile_image} /></div>
                                        :
                                        <div className="user-avtar-img"><PersonIcon /></div>
                                    }
                                </div>
                                <span className="text-username">{this.loggedUser.first_name}</span>{/*<span className="caret down"></span>*/}

                                <Popper className="custom-dropdown" open={this.state.dropDownOpen} anchorEl={this.anchorRef.current} transition disablePortal>
                                    {({ TransitionProps }) => (
                                        <Grow
                                            {...TransitionProps}
                                        >
                                            <Paper id="menu-list-grow">
                                                <ClickAwayListener onClickAway={this.handleClose}>
                                                    <MenuList>
                                                        <MenuItem>
                                                            <NavLink to="/dashboard/profile"><span>{i18n.t('Profile')}</span></NavLink>
                                                        </MenuItem>

                                                         {/*
                                                        <MenuItem>
                                                            <NavLink to="/dashboard/profile/?settings"><span>{i18n.t('Settings')}</span></NavLink>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <NavLink to="/dashboard/profile/?verification"><span>{i18n.t('Verification')}</span></NavLink>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <Link underline="none"><span>{i18n.t('Invite friends')}</span></Link>
                                                        </MenuItem> */}
                                                        <MenuItem onClick={this.logOut}>
                                                            <Link underline="none"><span>{i18n.t('Logout')}</span></Link>
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </ListItem>
                        </>
                    ) : (
                            <>
                                {/* <ListItem>
                                    <ListItemText>
                                        <NavLink to="/">{i18n.t('Howto')}</NavLink>
                                    </ListItemText>
                                </ListItem> */}
                                <ListItem>
                                    <ListItemText>
                                        <NavLink to="/login"><Button color="inherit" className='btn-primary'>{i18n.t('Login')}</Button></NavLink>
                                    </ListItemText>
                                </ListItem >
                                <ListItem >
                                    <ListItemText>
                                        <NavLink to="/register"><Button color="inherit" className='btn-primary'>{i18n.t('Register')}</Button></NavLink>
                                    </ListItemText>
                                </ListItem>
                            </>
                        )
                }
            </List>
        )
    }
}

function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    parseJwt: userActions.parseJwt,
    logOut: userActions.logout,
    getUserDetails: userActions.getUserDetails,
}

const connectedHeaderLinks = connect(mapState, actionCreators)(HeaderLinks);
export { connectedHeaderLinks as HeaderLinks };
