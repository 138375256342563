import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { userActions, alertActions } from '../../_actions';
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import i18n from "../../i18n";
import { NavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
import NotificationBox from '../../components/GeneralComponent/NotificationBox';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import 'react-table/react-table.css';
import "./styles.scss";
import CloseIcon from "@material-ui/icons/Close";
import FooterComponent from "../../components/FooterComponent";


const MySwal = withReactContent(Swal)

class Orders extends Component {
    constructor(props) {
        super(props)

        this.state = {
            AllOrders: [],
            SelectedOrder: {},
            allSortedOrders: [],
            isFooterOpen: false,
        }
        this.getOrdersSortedComponent = this.getOrdersSortedComponent.bind(this);
    }


    componentDidMount() {
        this.props.getUserOrders();
    }


    static getDerivedStateFromProps = (props, state) => {
        const { user_orders } = props.users;
        var update = {}
        if (user_orders && user_orders.status === true) {
            update = {
                ...update,
                AllOrders: user_orders.data
            }
        }

        return update;
    }



    getOrdersSortedComponent(id) {
        let sortInfo = this.state.allSortedOrders.filter(item => item.id === id);
        if (sortInfo.length) {
            if (sortInfo[0].desc === true) return <FontAwesomeIcon icon={faSortAmountDown} />;
            if (sortInfo[0].desc === false) return <FontAwesomeIcon icon={faSortAmountUp} />;
        }
        return <FontAwesomeIcon icon={faSortAmountDown} />;
    }


    deleteOrder = (order) => {
        MySwal.fire({
            title: <span>{i18n.t('Are you sure to delete this order?')}</span>,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: i18n.t('Yes, delete it!'),
            cancelButtonText: i18n.t('No, keep it')
        }).then((result) => {
            if (result.value) {
                this.props.deleteUserOrder(order).then(() => {
                    this.props.getUserOrders();
                })
            }
        })
    }

    toggleFooter = () => {
        this.setState({ isFooterOpen: !this.state.isFooterOpen })
    }

    render() {
        const { getOrdersSortedComponent } = this;
        const { allSortedOrders, AllOrders } = this.state;
        const { alert } = this.props;

        const genericHeaderArrows = () => {
            return {
                Header: props => {
                    const Sorted = getOrdersSortedComponent(props.column.id);
                    return (<span>{props.column.headerText} {Sorted}</span>);
                },
                headerStyle: { boxShadow: "none" }
            };
        };

        const OrdersColumns = [
            {
                ...genericHeaderArrows(),
                headerText: i18n.t("Asset Name"),
                accessor: 'property_master.title',
                width: 250,
            },
            {

                ...genericHeaderArrows(),
                id: 'order_type',
                headerText: i18n.t("Order type"),
                accessor: row => i18n.t(row.order_type),
                width: 150,
            }, {
                ...genericHeaderArrows(),
                headerText: i18n.t("Price / Share"),
                accessor: 'price',
                width: 150,
            }, {
                ...genericHeaderArrows(),
                headerText: i18n.t("Shares"),
                accessor: 'quantity',
                width: 150,
            }, {
                ...genericHeaderArrows(),
                headerText: i18n.t("Amount"),
                accessor: 'amount',
                width: 150,
            },
            // {
            //     ...genericHeaderArrows(),
            //     headerText: i18n.t("Fee"),
            //     accessor: 'fee',
            //     width: 100,
            // }, {
            //     ...genericHeaderArrows(),
            //     headerText: i18n.t("Fee Amount"),
            //     accessor: 'fee_amount',
            //     width: 120,
            // },
            {
                ...genericHeaderArrows(),
                headerText: i18n.t("Total amount"),
                accessor: 'total_amount',
                width: 150,
            },
            {
                headerText: '',
                accessor: 'id',
                width: 140,
                Cell: props => <Button className="btn-order-cancel" onClick={() => this.deleteOrder(props.original)}><DeleteOutlineIcon /></Button>
            }
        ]

        return (
            <div className='dashboard-content'>
                <NavBarComponent pageName="orders" />
                <main className="main-content orders-page">
                    {alert.message && <NotificationBox open={true} variant={alert.type} message={alert.message} />}
                    <p className="widget-block-title">{i18n.t('All Orders')}</p>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <div className="table-widget all-investments-block">
                                {/* <div className="table-header">
                                    <div className="left-block">
                                        <TextField
                                            fullWidth
                                            InputProps={{
                                                className: "search-box",
                                                placeholder: i18n.t("Find Your Asset Type to search"),
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </div> */}
                                <Scrollbars style={{ height: 400 }} autoHide autoHideTimeout={1000}>
                                    <ReactTable
                                        data={AllOrders}
                                        columns={OrdersColumns}
                                        showPagination={false}
                                        resizable={false}
                                        sortable={true}
                                        sorted={allSortedOrders}
                                        onSortedChange={sorted => this.setState({ allSortedOrders: sorted })}
                                        getTdProps={() => {
                                            return {
                                                onClick: (e, handleOriginal) => {
                                                    if (handleOriginal) {
                                                        handleOriginal()
                                                    }
                                                }
                                            }
                                        }}
                                        minRows={0}
                                    />
                                </Scrollbars>

                                <div className="table-box-shadow"></div>
                            </div>
                        </Grid>
                    </Grid>
                    {
                        this.state.isFooterOpen
                            ? <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}><CloseIcon /> {i18n.t('Close')}</Button>
                            : <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}>{i18n.t('GTC, Privacy policy & more')}</Button>
                    }
                </main>

                {
                    this.state.isFooterOpen ?
                        <div className="footer-block">
                            <FooterComponent />
                        </div>
                        : null
                }
            </div>
        );
    }
}



function mapState(state) {
    const { users, alert } = state;
    return { users, alert };
}

const actionCreators = {
    getUserOrders: userActions.getUserOrders,
    deleteUserOrder: userActions.deleteUserOrder,
    clearAlerts: alertActions.clear,
}

export default connect(mapState, actionCreators)(Orders);

