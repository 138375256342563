import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Box, Grid } from '@material-ui/core';
import i18n from "../../i18n";
import { userActions } from '../../_actions';
import InvestmentModal from '../../components/DashboardComponent/modals/InvestmentModal';
import OrderBookModal from '../../components/DashboardComponent/modals/OrderBookModal';
import InvetmentFooter from './InvetmentFooter';
import { history } from '../../_helpers';
import "./styles.scss";


class PropertyInvestmentBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalStatus: false,
            isUserLoggedIn: false
        }
    }

    componentDidMount() {
        var tokenInfo = this.props.parseJwt();
        if (tokenInfo && tokenInfo.status) {
            this.setState({ isUserLoggedIn: true });
        }
    }

    openInvestmentModal = () => {

        if (this.state.isUserLoggedIn) {
            if (this.props.propertyId) {
                this.props.onModalOpen();
                this.props.getUserDetails();
                this.setState({ modalStatus: true });
            }
        } else {
            history.push('/login', { next: `/property/view/${this.props.propertyId}` })
        }
    }

    openOrderBookModal = () => {
        if (this.state.isUserLoggedIn) {
            if (this.props.propertyId) {
                this.props.getUserDetails();
                this.props.getSettings();
                this.props.getUserRecentTransactions(this.props.propertyId);
                this.props.getUsersOrders(this.props.propertyId);
                this.setState({ modalStatus: true });
            }
        } else {
            history.push('/login', { next: `/property/view/${this.props.propertyId}` })
        }
    }

    closeModal = () => {
        this.props.onModalClose();
        this.setState({ modalStatus: false });
    }

    formatePrice = (val) => {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = this.numberWithCommas(val);
            return val;
        }
    }

    numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }

    render() {
        const {
            top, isSticky, isFooter, height,
            property, propertyId,
            style
        } = this.props;

        var issuer_name = undefined
        if (property.property_project_partners && property.property_project_partners[0] && property.property_project_partners[0].partner_master) {
            issuer_name = property.property_project_partners[0].partner_master.name
        }

        return (
            <>
                {property && <div
                    className='property-investment-box'
                    style={style}
                >
                    {property.property_type === "primary" ? (
                        <div className="property-investment-box-primary">
                            <table className="table table-block-1">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className='txt-value'>{this.formatePrice(property.profit_payout_percent)} % <span>p.a.*</span></p>
                                            <p className='txt-title'>{i18n.t('Net rental return')}</p>
                                        </td>
                                        <td>
                                            <p className='txt-value'>{this.formatePrice((property.profit_payout_percent * 1 + property.predicted_value_increase_p_a * 1).toFixed(2))} % <span>p.a.*</span></p>
                                            <p className='txt-title'>{i18n.t('Total Return')} </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table table-block-2">
                                <tbody>
                                    <tr>
                                        <td>{i18n.t('investbox_Net rental return')}</td>
                                        <td>{property.dividend_distribution_name !== '' ? property.dividend_distribution_name : i18n.t('Unknown')}</td>
                                    </tr>
                                    <tr>
                                        <td>{i18n.t('investbox_Type of real estate')}</td>
                                        <td>{i18n.t('Rental')}</td>
                                    </tr>
                                    <tr>
                                        <td>{i18n.t('investbox_Financial Instrument')}</td>
                                        <td>{property.financial_instrument_name !== '' ? property.financial_instrument_name : 'Unknown'}</td>
                                    </tr>
                                    {issuer_name && (
                                        <tr>
                                            <td>{i18n.t('Issuer')}</td>
                                            <td>{issuer_name}</td>
                                        </tr>
                                    )}
                                    {/* <tr>
                                        <td>{i18n.t('investbox_Issuer')}</td>
                                        <td>{(property.issuer_details && property.issuer_details.first_name && property.issuer_details.last_name) ?
                                            property.issuer_details.first_name + " " + property.issuer_details.last_name
                                            : ''}
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>

                            <Box
                                mt={3}
                                className={(isSticky || top < 0) ? 'mobile-btn-invest' : ''}
                            >
                                <Button onClick={this.openInvestmentModal} variant="contained" className="btn-invest btn-secondary">{i18n.t('Invest Now')}</Button>
                            </Box>

                            {this.state.isUserLoggedIn && (
                                <InvestmentModal
                                    handleClose={this.closeModal}
                                    open={this.state.modalStatus}
                                    property={this.props.property}
                                    propertyId={propertyId}
                                />
                            )}

                        </div>
                    ) : (
                            <div className="property-investment-box-secondary">
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="value-block">
                                        <p className="txt-title block-1">
                                            <span>{i18n.t('Invest Now')}</span>
                                        </p>
                                        <p className="txt-value block-1">
                                            <span>&euro; {property.lowest_available_brick_price}</span>
                                        </p>
                                        <p className="txt-information block-1">
                                            <span>{i18n.t('Lowest Available Brick Price')}</span>
                                        </p>
                                        <div
                                            className={(isSticky || top < 0) ? 'mobile-btn-order txt-action block-1' : 'txt-action block-1'}
                                        >
                                            <Box mt={3} >
                                                <Button onClick={this.openOrderBookModal} variant="contained" className="btn-order btn-secondary">{i18n.t('Current offers')}</Button>
                                            </Box>
                                        </div>
                                    </div>
                                </Grid>
                                {this.state.isUserLoggedIn && (
                                    <OrderBookModal
                                        handleClose={this.closeModal}
                                        open={this.state.modalStatus}
                                        property={this.props.property}
                                        tabIndex={0}
                                    />)}
                            </div>
                        )}
                </div>}


                {(isFooter && top < (height * -1)) && <InvetmentFooter
                    property={this.props.property} onClick={this.props.property.property_type === "primary" ? this.openInvestmentModal : this.openOrderBookModal} />}
            </>
        )
    }
}
function mapState(state) {
    const { users, authentication } = state;
    return { users, authentication };
}

const actionCreators = {
    parseJwt: userActions.parseJwt,
    getUserRecentTransactions: userActions.getUserRecentTransactions,
    getUsersOrders: userActions.getUsersOrders,
    getUserDetails: userActions.getUserDetails,
    getSettings: userActions.getSettings,
}

export default connect(mapState, actionCreators)(PropertyInvestmentBox);
