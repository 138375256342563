import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import ReactHtmlParser from 'react-html-parser';
import i18n from "../../i18n";
import "./styles.scss";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(1),
    },
    partnerImage: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginRight: theme.spacing(2),
    }
}));

export function PartnerList(props) {
    const classes = useStyles();

    return (
        <List className={`${classes.root} partner-list-root`}>
            {props.partners.map((partner, index) => {
                var { involvement, partner_master } = partner;
                if (partner_master) {
                    var partner_name = partner_master.name
                    var partner_logo = partner_master.logo
                    var partner_description = partner_master.description
                }
                return (
                    <div key={index} className="list-item-container">
                        <ListItem className="list-item" alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar className={classes.partnerImage} alt="test img" src={partner_logo ? partner_logo : ''}>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>
                                <>
                                    <div className="text-primary">
                                        <p><strong>{partner_name ? partner_name : ''}</strong></p>
                                        <p><strong>{involvement}</strong></p>
                                    </div>
                                    <div className="text-secondary">
                                        <p className="txt-description">{ReactHtmlParser(partner_description ? partner_description : '')}</p>
                                    </div>
                                    <Divider className="list-item-divider" variant="inset" component="div" />
                                </>
                            </ListItemText>
                        </ListItem>
                    </div>
                )
            })}
        </List>
    );
}

class PatnerDetails extends Component {
    render() {
        const { property } = this.props;

        return (
            <div className='partners-details'>
                <h2 className="title-text">{i18n.t('Partners')}</h2>
                {property.property_project_partners && property.property_project_partners.length > 0 ?
                    <PartnerList
                        partners={property.property_project_partners}
                    />
                    :
                    <Grid container>
                        <Grid item md={3} xs={12}>
                            <p>{i18n.t("Not available")}</p>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    }
}

export default PatnerDetails;
