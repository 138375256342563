import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropertyBoxComponent from '../../components/PropertyBoxComponent';
import i18n from "../../i18n";
import "./styles.scss";

class SearchResultsListings extends Component {
    render() {
        const { properties } = this.props;

        return (
            properties.length > 0 && (<div className='search-lising-section'>
                <h2 className='block-title'>{i18n.t('All Properties at a glance')}</h2>

                <Grid container spacing={2}>
                    {properties.map((property, index) => {
                        return (<Grid key={index} item md={4} xs={12}>
                            <PropertyBoxComponent variant={property.property_type} property={property} />
                        </Grid>)
                    })}
                </Grid>
            </div>)
        )
    }
}

export default SearchResultsListings;