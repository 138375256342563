import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import i18n from "../../i18n";
import RoomIcon from '@material-ui/icons/Room';
import "react-multi-carousel/lib/styles.css";
import "./styles.scss";
import { initialState } from '../../_reducers/general.reducer';

const responsive = {
    mobile: {
        breakpoint: { max: 4000, min: 0 },
        items: 1,
    },
};

const PropertyImagesSlider = (props) => {
    const { property_images, propertyId } = props;
    return (
        <Carousel responsive={responsive}>
            {property_images.map((image, index) => {
                return (<div key={index}>
                    <Link to={"/property/view/" + propertyId}><div className="property-image" style={{ backgroundImage: `url('` + image.image_path + `')` }} ></div></Link>
                </div>)
            })}
        </Carousel>
    )
};

const PropertyFinancialBlock = (props) => {
    const { property, variant } = props;

    function formatePrice(val) {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = numberWithCommas(val);

            return val;
        }
    }

    function numberWithCommas(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }

    return (
        <>
            <p className='property-location'><RoomIcon />
                {/* {property.address_city !== '' && property.address_country !== '' ?
                    (<span>{property.address_city + ', ' + property.address_country}</span>) :
                    <span>Not provided</span>
                } */}
 <span>{(initialState.languageCode === 'en') ? property.macrolocation_heading['en'] : property.macrolocation_heading['de']}, {i18n.t(property.address_country)}</span>
                {(variant === "primary") && <span className='badge-new'>{i18n.t('NEW')}</span>}
            </p>

            <table className='property-financial-block'>
                <tbody>
                    {(variant === "primary")
                        ?
                        <tr>
                            <td>
                                <p className='txt-value'>&euro; {numberWithCommas(property.financing_volume)}</p>
                                <p className='txt-title'>{i18n.t('Property Price')}</p>
                            </td>
                            <td>
                                <p className='txt-value'>&euro; {formatePrice(property.price_per_share)}</p>
                                <p className='txt-title'>{i18n.t('Min. Investment')}</p>
                            </td>
                            <td>
                                <p className='txt-value'>{formatePrice(property.profit_payout_percent)} %</p>
                                <p className='txt-title'>{i18n.t('Net rental return')}</p>
                            </td>
                        </tr>
                        :
                        <tr>
                            <td>
                                <p className='txt-value'>&euro; {numberWithCommas((property.current_price_per_share * property.total_share_count).toFixed(0))}</p>
                                <p className='txt-title'>{i18n.t('Market Value')}</p>
                            </td>
                            <td>
                                <p className='txt-value'>&euro; {formatePrice(property.current_price_per_share)}</p>
                                <p className='txt-title'>{i18n.t('Price per Share')}</p>
                            </td>
                            <td>
                                <p className='txt-value'>{formatePrice(((property.distribution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2))} %</p>
                                <p className='txt-title'>{i18n.t('Net rental return')}</p>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </>
    )
};

export default function PropertyBoxComponent(props) {
    const { type, variant, property } = props;

    return (
        <Card className={type + "-view " + variant + "-type property-card-box"}>
            <div className='property-images'>
                <PropertyImagesSlider propertyId={property.property_id} property_images={(property.property_images && property.property_images.length > 0) ? property.property_images : []} />
                <div className='property-details'>
                    <Link to={"/property/view/" + property.property_id}><p className='property-name'>{property.title}</p></Link>
                </div>
            </div>

            <CardContent className='property-content'>
                <Link to={"/property/view/" + property.property_id}><PropertyFinancialBlock property={property} variant={variant} /></Link>
            </CardContent>
        </Card>
    );
}
PropertyBoxComponent.defaultProps = {
    type: 'grid'
}
