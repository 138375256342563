import React, { Component } from 'react';
import { Grid, Container, Select, FormControl, MenuItem, Button, Paper } from '@material-ui/core';
import InputRange from 'react-input-range';
import { history } from '../../_helpers';
import i18n from "../../i18n";
import { Popper, Grow } from "@material-ui/core";
import "react-input-range/lib/css/index.css";
import "./styles.scss";

class HeroBanner extends Component {
    constructor(props) {
        super(props);

        this.search_filter = React.createRef();

        this.state = {
            searchFilterOpen: false,
            dividendYieldRange: { min: 0, max: 10 },
            search: {
                location: [],
                status: "",
                propertyValue: { min: 0, max: 330000 },
                pricePerShareValue: { min: 0, max: 340 },
                dividendYieldValue: { min: 0, max: 4.2 },
            },
            locations: [],
            properties: {}
        }
    }

    componentWillReceiveProps(props) {
        const { properties, locations } = props;
        if (properties && properties !== this.state.properties) {
            this.setState({
                ...this.state,
                properties: properties,
                search: {
                    ...this.state.search,
                    'propertyValue': {
                        min: 0,
                        max: properties.max_property_value ? properties.max_property_value : 0
                    },
                    'pricePerShareValue': {
                        min: 0,
                        max: properties.max_price_per_share ? properties.max_price_per_share : 0
                    },
                    'dividendYieldValue': {
                        min: 0,
                        max: properties.max_dividend_yield ? properties.max_dividend_yield : 0
                    }
                },
                dividendYieldRange: {
                    min: 0,
                    max: properties.max_dividend_yield ? properties.max_dividend_yield : 0
                }
            });
        }

        if (locations) {
            this.setState({
                ...this.state,
                locations: locations
            })
        }

        return null;
    }


    toggleSearchFilter = () => {
        this.setState({ searchFilterOpen: !this.state.searchFilterOpen });
    }

    setFilter = (value, type) => {
        this.setState(prevState => ({
            search: {
                ...prevState.search,
                [type]: value
            }
        }));
    }

    handleChange = (event) => {
        const { search } = this.state;
        search[event.target.name] = event.target.value;
        this.setState({ search });
    }

    formateYeild(value) {
        return value.toFixed(1).toString().replace(".", ",")
    }

    searchProperty = () => {
        history.push('/marketplace', { searchInvestment: this.state.search });
    }


    render() {
        const { search, locations } = this.state;

        return (
            <div className='hero-banner' style={{backgroundImage: (this.props.background != '' ? `url(${this.props.background})` : `url("/home-bg-image.jpg")`)}}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <div className="search-form">
                                <p className='search-title'>Real Estate tokenisation platform for crowd investing, backed by the 0bsnetwork Blockchain For The Real World platform</p>

                                <FormControl className='form-control'>
                                    <Select
                                        multiple
                                        inputProps={{
                                            name: 'location',
                                            id: 'search-location'
                                        }}
                                        disableUnderline
                                        value={search.location}
                                        onChange={this.handleChange}
                                        displayEmpty
                                        renderValue={selected => {
                                            if (selected.length === 0) {
                                                return <span>{i18n.t('Location')}</span>;
                                            }

                                            return selected.join(', ');
                                        }}
                                    >
                                        <MenuItem value="" disabled>{i18n.t('Location')}</MenuItem>
                                        {locations.length > 0 && locations.map((city, index) => (
                                            <MenuItem key={index} value={city.name}>
                                                {city.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className='form-control'>
                                    <Button variant="outlined" className="btn-filter" ref={this.search_filter} onClick={this.toggleSearchFilter} >{i18n.t('Net rental return')}</Button>

                                    <Popper className="filter-box" open={this.state.searchFilterOpen} anchorEl={this.search_filter.current} role={undefined} transition disablePortal placement={"bottom-start"}>
                                        {({ TransitionProps }) => (
                                            <Grow
                                                {...TransitionProps}
                                            >
                                                <Paper className="filter-content">
                                                    <p className="txt-title">{i18n.t('Net rental return')}</p>

                                                    <div className="range-slider">
                                                        <InputRange
                                                            maxValue={this.state.dividendYieldRange.max}
                                                            minValue={this.state.dividendYieldRange.min}
                                                            step={0.1}
                                                            value={this.state.search.dividendYieldValue}
                                                            onChange={value => this.setFilter(value, 'dividendYieldValue')} />
                                                        <p className="range-value">
                                                            <span>% {this.formateYeild(this.state.search.dividendYieldValue.min)}</span>
                                                            <span>-</span>
                                                            <span>% {this.formateYeild(this.state.search.dividendYieldValue.max)}</span>
                                                        </p>
                                                    </div>

                                                    <div className="action-footer">
                                                        <Button className="btn-delete" onClick={this.toggleSearchFilter}>{i18n.t("Delete")}</Button>
                                                        <Button className="btn-save" onClick={this.toggleSearchFilter}>{i18n.t("Save")}</Button>
                                                    </div>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </FormControl>
                                <Button variant="contained" onClick={this.searchProperty} className="btn-secondary">{i18n.t('Search')}</Button>
                                <p style={{marginTop: 20, marginBottom: -10}}>Built by Blockstruct GmbH</p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}

export { HeroBanner };
