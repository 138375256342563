import React, { Component } from 'react';
import "./styles.scss";
import {Grid} from "@material-ui/core";
import Gallery from "react-grid-gallery";

class PropertyImageSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openGallery: false
        }
    }

    openGalleryModal = () => {
        this.props.onModalOpen();
        this.setState({
            openGallery: true
        })
    }

    closeGalleryModal = () => {
        this.props.onModalClose();
        this.setState({
            openGallery: false
        })
    }

    handleDataTransformation = (property_images) => {
        return property_images.map((image_item) => {
            return {
                src: image_item.image_path,
                thumbnailWidth: 300,
                thumbnailHeight: 300,
                thumbnail: image_item.image_path
            }
        });
    }

    render() {
        const { property, open } = this.props;

        return (
            <div className='property-images-slider'>
                {property.property_images && property.property_images.length > 0 && (
                    <div className="image" style={{ backgroundImage: `url('` + property.property_images[0].image_path + `')` }} onClick={this.openGalleryModal} ></div>
                )}
                {property && property.property_images && property.property_images.length > 0 && this.state.openGallery && (<Grid container>
                    <Grid item md={12} xs={12}>
                        <Gallery
                            margin={0}
                            images={this.handleDataTransformation(property.property_images)}
                            thumbnailImageComponent={ImageComponent}
                            enableImageSelection={false}
                            showLightboxThumbnails={true}
                            isOpen={true}
                            lightboxWillClose={this.closeGalleryModal}
                        />
                    </Grid>
                </Grid>)}
            </div>
        )
    }
}

class ImageComponent extends React.Component {
    render() {
        return <img alt="Property" className="property-image" {...this.props.imageProps} />;
    }
}

export default PropertyImageSlider;
