import React, { Component } from 'react';
import config from './../../config.json';
import GoogleMapReact from 'google-map-react';
import ReactHtmlParser from 'react-html-parser';
import RoomIcon from '@material-ui/icons/Room';
import { Avatar } from "@material-ui/core";
import "./styles.scss";
import i18n from "../../i18n";


const PropertyMarker = () => <div className="marker"><RoomIcon /></div>;


class LocationDetails extends Component {
    getPropertyLocation = (property) => {
        return {
            lat: parseFloat(property.location_coords.lat),
            lng: parseFloat(property.location_coords.lng)
        }
    }

    render() {
        const { property } = this.props;

        return (
            <div className='location-details'>
                {(property && property.macrolocation_heading !== '' && property.macrolocation_description !== '') && <div>
                    {/* <h2 className="txt-title">{i18n.t("Macro Location")}</h2> */}
                    <p className="title-text">{property.macrolocation_heading}</p>
                    {property.macrolocation_image_path !== "" && (
                        <Avatar
                            className="location-image"
                            alt="Macrolocation Picture"
                            variant="square"
                            src={property.macrolocation_image_path}
                        />
                    )}
                    <div className="content-text">{ReactHtmlParser(property.macrolocation_description)}</div>
                </div>}
                {(property && property.microlocation_heading !== '' && property.microlocation_description !== '') && <div>
                    {/* <h2 className="txt-title">{i18n.t("Micro Location")}</h2> */}
                    <p className="title-text">{property.microlocation_heading}</p>
                    {property.microlocation_image_path !== "" && (
                        <Avatar
                            className="location-image"
                            alt="Microlocation Picture"
                            variant="square"
                            src={property.microlocation_image_path}
                        />
                    )}
                    <div className="content-text">{ReactHtmlParser(property.microlocation_description)}</div>
                </div>}

                {property && property.location_coords && <div style={{ height: '370px', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: config.GOOGLE_API_KEY }}
                        defaultZoom={config.DEFAULT_MAP_LOCATION.zoom}
                        center={this.getPropertyLocation(property)}
                    >
                        <PropertyMarker lat={property.location_coords.lat} lng={property.location_coords.lng} />
                    </GoogleMapReact>
                </div>}
            </div>
        )
    }
}

export default LocationDetails;
