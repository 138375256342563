import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Typography, Link, Dialog, DialogContent, Button } from '@material-ui/core'
import NotificationBox from './../../components/GeneralComponent/NotificationBox';
import { userActions, alertActions } from '../../_actions';
import { history } from '../../_helpers';
import i18n from "../../i18n";
import "./styles.scss";

class LoginPage extends Component {
    constructor(props) {
        super(props);

        var isConfirm = false;
        if (history.location && history.location.search === "?success") {
            isConfirm = true;
        }

        this.state = {
            user: {
                email: "",
                password: "",
            },
            isConfirm: isConfirm
        }
        this.props.clearAlerts();
    }

    handleChange = (event) => {
        const { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }

    generateToken(length) {
        return Math.random().toString(36).substr(2, length);
    }

    handleSubmit = () => {
        this.props.clearAlerts();

        var email_id = this.state.user.email;
        var password = this.state.user.password;


        if (email_id && password) {
            this.props.login(email_id, password);
        }

        setTimeout(() => {
            const historyState = history.location.state;
            if (this.props.loggedIn !== undefined && this.props.loggedIn) {
                if (historyState && historyState !== undefined && historyState.next && historyState.next !== undefined) {
                    window.location = historyState.next;
                } else {
                    window.location = "/";
                }
            }
        }, 1000)
    }

    render() {
        const { user } = this.state;
        const { alert } = this.props;

        return (
            <div className='login-box'>
                {/* <Button variant="outlined" color="default" className="btn-primary btn-social-login">{i18n.t('Login with Google')}</Button> */}

                <ValidatorForm
                    className='default-form'
                    onSubmit={this.handleSubmit}
                    ref="form"
                    autoComplete="off">

                    {alert.message &&
                        <NotificationBox open={true} variant={alert.type} message={alert.message} />
                    }

                    <TextValidator
                        fullWidth
                        value={user.email}
                        onChange={this.handleChange}
                        name="email"
                        className="form-control"
                        id="outlined-name"
                        label={i18n.t('Email Address')}
                        margin="normal"
                        variant="outlined"
                        validators={['required', 'isEmail']}
                        errorMessages={[i18n.t('this field is required'), i18n.t('Email address is not valid')]}
                    />
                    <TextValidator
                        fullWidth
                        value={user.password}
                        onChange={this.handleChange}
                        name="password"
                        type="password"
                        className="form-control"
                        id="outlined-name"
                        label={i18n.t('Password')}
                        margin="normal"
                        variant="outlined"
                        validators={['required']}
                        errorMessages={[i18n.t('this field is required')]}
                    />
                    <Typography align='left' variant="body2">
                        <Link className='primary-link'>{i18n.t('forgot password? click here to recover your password')}</Link>
                    </Typography>

                    <Button type="submit" variant="outlined" color="default" className="btn-primary btn-submit">{i18n.t('Login')}</Button>

                </ValidatorForm>

                <Typography align='center' variant="body2">
                    <NavLink to="/register" className='primary-link'>{i18n.t('Not registered yet? Click here to register')}</NavLink>
                </Typography>

                <Dialog
                    open={this.state.isConfirm}
                    onClose={() => this.setState({ isConfirm: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className='modal-success'>
                        <Typography align='center' color="initial" variant="body2">
                            {i18n.t('Thank you for confirming your email address. You can now log in.')}
                        </Typography>

                        <Typography align='center' variant="body2">
                            <Button variant="outlined" color="default" onClick={() => this.setState({ isConfirm: false })} className="btn-primary btn-login btn-width-300">{i18n.t('Login')}</Button>
                        </Typography>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

function mapState(state) {
    const { loggedIn } = state.authentication;
    const { alert } = state;
    return { loggedIn, alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    login: userActions.login,
    logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };

export default LoginPage;