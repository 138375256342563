import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Hidden, Drawer } from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import NavBarLinks from "./NavBarLinks";
import { HeaderLinks } from "./HeaderLinks";
import openBrixLogo from './../../../assets/images/logo.png'
import styles from "../Style";
import "../styles.scss";

const useStyles = makeStyles(styles);


export function NavBarComponent(props) {
    const classes = useStyles();
    const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

    const handleNavDrawerToggle = () => {
        setMobileNavOpen(!mobileNavOpen);
    };

    const { leftLinks, absolute, pageName } = props;
    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes.absolute]: absolute,
        [classes.fixed]: "true"
    });

    const brandComponent = <div className="dashboard-nav-logo"><a href="/" className={classes.title}><img src={openBrixLogo} className={classes.navDrawerImg} alt="" /></a></div>;

    return (
        <AppBar className={appBarClasses}>
            <Toolbar disableGutters className={classes.container}>
                <Hidden mdUp>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleNavDrawerToggle}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>

                {leftLinks !== undefined ? brandComponent : null}
                <div className={classes.flex}>
                    {leftLinks !== undefined ? (
                        <Hidden smDown implementation="css">
                            {leftLinks}
                        </Hidden>
                    ) : (
                            brandComponent
                        )}
                </div>
                <Hidden smDown implementation="css">
                    <HeaderLinks page={pageName} />
                </Hidden>
            </Toolbar>

            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classes.appDrawerPaper,
                    }}
                >
                    <NavBarLinks page={pageName} />
                </Drawer>
            </Hidden>

            <Hidden mdUp implementation="js">
                <Drawer
                    variant="temporary"
                    open={mobileNavOpen}
                    classes={{
                        paper: classes.appDrawerPaper,
                    }}
                    onClose={handleNavDrawerToggle}
                >
                    <div className={classes.navBarTitle}>
                        {brandComponent}
                    </div>

                    <NavBarLinks page={pageName} navControl={handleNavDrawerToggle} />
                </Drawer>
            </Hidden>
        </AppBar>
    );
}

NavBarComponent.defaultProp = {
    color: "white"
};

NavBarComponent.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool
};