import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generalActions, alertActions } from '../../_actions';
import { StickyContainer, Sticky } from 'react-sticky';
import { Container, Grid, Button, Hidden } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';
import i18n from "../../i18n";
import Loader from '../../components/GeneralComponent/Loader';
import FooterComponent from '../../components/FooterComponent';
import PropertyMapView from '../../components/PropertyMapView';
import SearchBar from './SearchBar';
import SearchResultsListings from './SearchResultsListings';
import NotificationBox from '../../components/GeneralComponent/NotificationBox';
import { history } from '../../_helpers';
import "./styles.scss";
import {Route} from "react-router-dom";

class SearchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFooterOpen: false,
      list_mode: true,
      initialDataLimit: 10,
      page: 1,
      properties: {
        total: 0,
        data: [],
        max_property_value: 0,
        max_price_per_share: 0,
        max_dividend_yield: 0,
      },
      isCompletePagination: false,
      search: {}
    }

    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.props.clearAlerts();
    if (!history.location.state) {
      this.props.getAllProperties(this.state.initialDataLimit, this.state.page);
    }

    document.addEventListener('scroll', this.trackScrolling);
  }


  isBottom(el) {
    return Math.round(el.getBoundingClientRect().bottom) <= window.innerHeight;
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    if (!this.state.isCompletePagination) {
      const wrappedElement = this.myRef.current;
      if (this.isBottom(wrappedElement)) {
        this.setState({
          page: this.state.page + 1
        }, () => {
          this.getProperties();
        })
      }
    }
  };

  getProperties = () => {
    if (JSON.stringify(this.state.search) !== JSON.stringify({}) && !this.state.isCompletePagination) {
      this.props.filterProperty(this.state.initialDataLimit, this.state.search, this.state.page)
    } else if (!this.state.isCompletePagination) {
      this.setState({
        ...this.state,
        search: {}
      }, () => this.props.getAllProperties(this.state.initialDataLimit, this.state.page)
      )
    }
  }

  componentWillReceiveProps(props) {
    if (props.properties && JSON.stringify(props.properties.data) !== JSON.stringify(this.state.properties.data) &&
      props.properties.data.length && !this.state.isCompletePagination) {
      if ((props.properties && !props.properties.status)) {
        this.setState({
          ...this.state,
          properties: this.state.properties,
          isCompletePagination: true
        });
      }

      if (this.state.properties && props.properties && JSON.stringify(this.state.properties.data) !== JSON.stringify(props.properties.data)) {
        let properties = props.properties.data;
        this.setState({
          ...this.state,
          isCompletePagination: properties.length !== this.state.initialDataLimit || properties.length === 0,
          properties: {
            total: props.properties.total,
            data: [...this.state.properties.data, ...properties],
            max_property_value: props.properties.max_property_value,
            max_price_per_share: props.properties.max_price_per_share,
            max_dividend_yield: props.properties.max_dividend_yield,
          }
        })

      }
    }
    if (history.location.state && history.location.state.searchInvestment) {
      this.setState({
        ...this.state,
        defaultInitialFilter: history.location.state.searchInvestment
      });
    }
  }

  toggleFooter = () => {
    this.setState({ isFooterOpen: !this.state.isFooterOpen })
  }

  toggleView = () => {
    this.setState({ list_mode: !this.state.list_mode })
  }

  updateFilters = (searchFilters) => {
    if (JSON.stringify(searchFilters) !== JSON.stringify(this.state.search)) {
      this.setState({
        ...this.state,
        search: searchFilters,
        page: 1,
        properties: {
          total: 0,
          data: [],
          max_property_value: 0,
          max_price_per_share: 0,
          max_dividend_yield: 0,
          availability:false
        },
        isCompletePagination: false,
      }, () => {
        this.getProperties();
      })
    }
  }

  render() {
    const { properties } = this.state;
    const { alert } = this.props;

    return (
      <>
        {(!properties || Object.keys(properties).length === 0) && (
          <Loader />
        )}
        {(properties && properties.data) && (
          <Container className="search-page" maxWidth={false}>
            <div className="search-bar">
              <SearchBar
                  properties={properties}
                  onUpdateFilters={(searchFilters) => this.updateFilters(searchFilters)}
              />
            </div>
            <div onScroll={this.handleScroll} ref={this.myRef}>

              {alert.message &&
                <NotificationBox open={true} variant={alert.type} message={alert.message} />
              }
              <StickyContainer>
                {(properties.data.length > 0) && (<Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={8} className='property-list-view'>
                    <Hidden xsDown>
                      <div onScroll={(event) => this.handleScroll(event)}>
                        <SearchResultsListings properties={properties.data} />
                      </div>
                    </Hidden>
                    {
                      this.state.list_mode &&
                      <Hidden smUp>
                        <div onScroll={this.handleScroll}>
                          <SearchResultsListings properties={properties.data} />
                        </div>
                      </Hidden>
                    }
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className='property-map-view'>
                    <Hidden xsDown>
                      <PropertyMapView properties={properties.data} />
                    </Hidden>
                    {

                      !this.state.list_mode &&
                      <Hidden smUp>
                        <PropertyMapView properties={properties.data} />
                      </Hidden>
                    }
                  </Grid>
                </Grid>)}

                {
                  this.state.isFooterOpen
                    ? <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}><CloseIcon /> {i18n.t('Close')}</Button>
                    : <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}>{i18n.t('GTC, Privacy policy & more')}</Button>
                }
              </StickyContainer>

              <Button variant="contained" className="btn-toggle-view" onClick={this.toggleView}>
                {this.state.list_mode ? <span>{i18n.t('Map')} <MapIcon /></span> : <span>{i18n.t('List')} <ListIcon /></span>}
              </Button>
            </div>
          </Container>
        )}
        {
          this.state.isFooterOpen ?
            <div className="footer-block">
              <FooterComponent />
            </div>
            : null
        }
      </>
    )
  }
}

function mapState(state) {
  const { general, alert } = state;
  const { properties } = general;
  return { properties, alert };
}

const actionCreators = {
  filterProperty: generalActions.filterProperty,
  getAllProperties: generalActions.getAllProperties,
  clearAlerts: alertActions.clear,
}

const connectedSearchPage = connect(mapState, actionCreators)(SearchPage);
export { connectedSearchPage as SearchPage };
