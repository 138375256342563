import React, { Component, Fragment } from 'react';
import { NavLink } from "react-router-dom";
import config from './../../config.json';
import GoogleMapReact from 'google-map-react';
import supercluster from 'points-cluster';
import RoomIcon from '@material-ui/icons/Room';
import "./styles.scss";

const InfoWindow = (props) => {
    const { property } = props;
    const isValidProperty = property && Object.keys(property).length > 0;
    function numberWithCommas(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }
    return (
        isValidProperty ? (
            <div className="info-window">
                <NavLink to={`/property/view/${property.property_id}`}>
                    <div className='cover-image-container'>
                        {property.property_images && property.property_images.length > 0 && (
                            <div className="image" style={{ backgroundImage: `url('` + property.property_images[0].image_path + `')` }} ></div>
                        )}
                    </div>
                    <div className="property-title">
                        <span>{property.title}</span>
                    </div>
                    <div className="property-address">
                        {property.address_city !== '' && property.address_country !== '' &&
                            <span>{property.address_city + ', ' + property.address_country}</span>
                        }
                    </div>
                    <div className="property-price">
                        {property.property_type === 'primary' &&
                            <span className='txt-value'>&euro; {numberWithCommas(property.financing_volume)}</span>
                        }
                    </div>
                </NavLink>
            </div>
        ) : <></>
    );
};


const Marker = (props) => {
    return (
        <Fragment>
            <div className="marker">
                <RoomIcon />
                <InfoWindow property={props.property} />
            </div>
        </Fragment>
    );
};


const ClusterMarker = (props) => {
    return (
        <div className="cluster-marker"> {props.points.length} </div>
    );
}


class PropertyMapView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            properties: [],
            available_location_cords: [],
            mapOptions: {
                center: config.DEFAULT_MAP_LOCATION.center,
                zoom: config.DEFAULT_MAP_LOCATION.zoom,
            },
            clusters: []
        }
    }
    mapInstance = null;
    mapsInstance = null;

    componentDidMount() {
        const { properties } = this.props;
        const tempArray = [];
        properties.map((property, index) => {
            var temp_location_coords = {
                lat: parseFloat(property.location_coords.lat),
                lng: parseFloat(property.location_coords.lng),
            }
            tempArray.push({ ...temp_location_coords, id: index })
            return null;
        });
        this.setState({ properties: properties, available_location_cords: tempArray });
    }

    componentDidUpdate = (prevProps) => {
        const { properties } = this.props;
        if (prevProps.properties !== properties) {
            const tempArray = [];
            properties.map((property, index) => {
                var temp_location_coords = {
                    lat: parseFloat(property.location_coords.lat),
                    lng: parseFloat(property.location_coords.lng),
                }
                tempArray.push({ ...temp_location_coords, id: index })
                return null;
            });
            this.setState({
                properties: properties,
                available_location_cords: tempArray
            }, () => {
                this.createClusters();
                if (this.mapInstance !== null && this.mapsInstance !== null) {
                    this.apiIsLoaded(this.mapInstance, this.mapsInstance, this.state.properties)
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.properties !== prevState.properties) {
            return {
                properties: nextProps.properties
            }
        }
        return null;
    }

    getMapBounds = (maps, properties) => {
        const bounds = new maps.LatLngBounds();
        properties.forEach((property) => {
            if (property.location_coords) {
                bounds.extend(new maps.LatLng(
                    parseFloat(property.location_coords.lat),
                    parseFloat(property.location_coords.lng),
                ));
            }
        });
        return bounds;
    };

    bindResizeListener = (map, maps, bounds) => {
        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });
    };

    apiIsLoaded = (map, maps, properties) => {
        this.mapInstance = map;
        this.mapsInstance = maps;
        if (properties.length > 0) {
            const bounds = this.getMapBounds(maps, properties);
            map.fitBounds(bounds);
            this.bindResizeListener(map, maps, bounds);
        }
    }


    getPropertyDetailByLocationCords = (lat, lng) => {
        const { properties } = this.state;
        var property = properties.find((property) => {
            const locationCords = {
                lat: parseFloat(property.location_coords.lat),
                lng: parseFloat(property.location_coords.lng),
            }
            if (locationCords.lat === lat && locationCords.lng === lng) {
                return property;
            }
            return null;
        })
        return {
            property: property !== null ? property : {}
        }
    }

    getClusters = () => {
        const clusters = supercluster(this.state.available_location_cords, {
            minZoom: 0,
            maxZoom: 16,
            radius: 30,
        });
        return clusters(this.state.mapOptions);
    };

    createClusters = () => {
        this.setState({
            clusters: this.state.mapOptions.bounds
                ? this.getClusters().map(({ wx, wy, numPoints, points }) => ({
                    lat: wy,
                    lng: wx,
                    numPoints,
                    id: `${numPoints}_${points[0].id}`,
                    points,
                }))
                : [],
        });
    };

    handleMapChange = ({ center, zoom, bounds }) => {
        this.setState({
            mapOptions: {
                center,
                zoom,
                bounds,
            }
        }, () => {
            this.createClusters();
        });
    }


    render() {
        const { properties, clusters } = this.state;


        return (
            <div style={this.props.style} className='property-map-section'>
                {properties.length > 0 && <div style={{ height: '100vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: config.GOOGLE_API_KEY }}
                        center={config.DEFAULT_MAP_LOCATION.center}
                        defaultZoom={config.DEFAULT_MAP_LOCATION.zoom}
                        onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps, properties)}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onChange={this.handleMapChange}
                    >
                        {clusters.map((item, index) => {
                            if (item.numPoints === 1) {
                                const itemLat = item.points[0].lat, itemLng = item.points[0].lng;
                                return (
                                    <Marker
                                        key={index}
                                        lat={itemLat}
                                        lng={itemLng}
                                        property={this.getPropertyDetailByLocationCords(itemLat, itemLng).property}
                                    />)
                            }
                            return (
                                <ClusterMarker
                                    key={item.id}
                                    isProperty={false}
                                    lat={item.lat}
                                    lng={item.lng}
                                    points={item.points}
                                />
                            );
                        })}
                    </GoogleMapReact>
                </div>}
            </div>
        )
    }
}

export default PropertyMapView;
