export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',
    PARSE_JWT: 'PARSE_JWT',

    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    GET_SECURITY_QUESTIONS_SUCCESS: 'GET_SECURITY_QUESTIONS_SUCCESS',
    GET_SECURITY_QUESTIONS_FAILURE: 'GET_SECURITY_QUESTIONS_FAILURE',

    GET_USER_INVESTMENTS_SUCCESS: 'GET_USER_INVESTMENTS_SUCCESS',
    GET_USER_INVESTMENTS_FAILURE: 'GET_USER_INVESTMENTS_FAILURE',

    GET_USER_COMMITTED_INVESTMENTS_SUCCESS: 'GET_USER_COMMITTED_INVESTMENTS_SUCCESS',
    GET_USER_COMMITTED_INVESTMENTS_FAILURE: 'GET_USER_COMMITTED_INVESTMENTS_FAILURE',

    GET_USER_LATEST_INVESTMENTS_SUCCESS: 'GET_USER_LATEST_INVESTMENTS_SUCCESS',
    GET_USER_LATEST_INVESTMENTS_FAILURE: 'GET_USER_LATEST_INVESTMENTS_FAILURE',

    GET_USER_PAYMENTS_SUCCESS: 'GET_USER_PAYMENTS_SUCCESS',
    GET_USER_PAYMENTS_FAILURE: 'GET_USER_PAYMENTS_FAILURE',

    GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
    GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',

    GET_USER_RECENT_TRANSACTION_SUCCESS: 'GET_USER_RECENT_TRANSACTION_SUCCESS',
    GET_USER_RECENT_TRANSACTION_FAILURE: 'GET_USER_RECENT_TRANSACTION_FAILURE',

    GET_USERS_ORDERS_SUCCESS: 'GET_USERS_ORDERS_SUCCESS',
    GET_USERS_ORDERS_FAILURE: 'GET_USERS_ORDERS_FAILURE',

    GET_USER_ORDERS_SUCCESS: 'GET_USER_ORDERS_SUCCESS',
    GET_USER_ORDERS_FAILURE: 'GET_USER_ORDERS_FAILURE',

    GET_USER_UNREALISED_PROFIT_SUCCESS: 'GET_USER_UNREALISED_PROFIT_SUCCESS',
    GET_USER_REALISED_PROFIT_SUCCESS: 'GET_USER_REALISED_PROFIT_SUCCESS',
    GET_USER_REALISED_PROFIT_DATA_SUCCESS: 'GET_USER_REALISED_PROFIT_DATA_SUCCESS',
};
