import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Hidden, IconButton, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import brickWiseLogo from './../../assets/images/logo.png';
import { HeaderLinks } from './HeaderLinks'
import './styles.scss';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  }
}));


export default function HeaderComponent(props) {
  const classes = useStyles();
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

  const handleNavDrawerToggle = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  console.log(props)

  useEffect(() => {
    console.log('its here' + props.logopath)
  }, [])

  const brandComponent = <NavLink className="nav-logo" to="/"><img src={props.logopath != '' ? props.logopath : brickWiseLogo} alt="Crowdfunding Real Estate Blockchain Logo" style={{backgroundColor: props.logobackground, paddingLeft: 10, paddingRight: 10}}/></NavLink>;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit" className="navbar">
        <Toolbar disableGutters>
          <Hidden mdUp>
            <IconButton
              className='btn-menu-toggle'
              color="inherit"
              aria-label="open drawer"
              onClick={handleNavDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          {brandComponent}

          <Hidden smDown implementation="css">
            <HeaderLinks />
          </Hidden>
        </Toolbar>

        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            open={mobileNavOpen}
            onClose={handleNavDrawerToggle}
          >
            <div style={{paddingTop:'56px', paddingLeft:'30px'}}>
              <HeaderLinks navControl={handleNavDrawerToggle} />
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    </div>
  )
}
