import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { generalActions } from '../../_actions';
import { HeroBanner } from './HeroBanner';
import { PropertiesMarketplace } from './PropertiesMarketplace';
import "./styles.scss";

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialDataLimit: 10
    }
  }

  componentDidMount() {
    this.props.getAllProperties(this.state.initialDataLimit);
    this.props.getAllLocations();
  }

  render() {
    const pmProperties = this.props.properties;
    const locations = this.props.locations;

    return (
      <>
        <HeroBanner
          properties={pmProperties}
          locations={locations}
          background={this.props.background}
        />

        <Container>
          <PropertiesMarketplace properties={pmProperties} />
        </Container>
      </>
    )
  }
}

function mapState(state) {
  const { general } = state;
  const { properties, locations } = general;
  return { properties, locations };
}

const actionCreators = {
  getAllProperties: generalActions.getAllProperties,
  getAllLocations: generalActions.getAllLocations,
}

export default connect(mapState, actionCreators)(HomePage);;
