import React, { Component } from 'react';
import { Link, Typography, Grid } from '@material-ui/core';
import { history } from '../../_helpers';
import i18n from "../../i18n";
import PropertyBoxComponent from '../../components/PropertyBoxComponent';
import Loader from '../../components/GeneralComponent/Loader';
import "./styles.scss";

class PropertiesMarketplace extends Component {
    showAllProperty = () => {
        history.push('/marketplace');
    }

    render() {
        const pmProperties = this.props.properties;

        return (
            <div className='block market-lising-section'>
                {pmProperties && Object.keys(pmProperties).length > 0 ?
                    <>
                        <h2 className='h2 block-title'>{i18n.t('Explore all Properties on the marketplace')}</h2>

                        <Grid container spacing={2}>
                            {pmProperties && pmProperties.data && pmProperties.data.length > 0 && pmProperties.data.map((property, index) => {
                                return (<Grid key={index} item md={3} sm={6} xs={12}>
                                    <PropertyBoxComponent variant={property.property_type} property={property} />
                                </Grid>)
                            })}
                        </Grid>

                        <Typography align='left' variant="body2">
                            <Link className="show-all-link" onClick={this.showAllProperty}>{i18n.t('Show all')} ({pmProperties && pmProperties.total ? pmProperties.total : 0}) ></Link>
                        </Typography>
                    </>
                    :
                    <>
                        <Loader />
                        <Typography align='center' variant="body2">{i18n.t("Loading properties...")}</Typography>
                    </>
                }
            </div>
        )
    }
}

export { PropertiesMarketplace };
