import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './_helpers';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import './assets/css/theme.scss';
import './assets/css/custom.scss';
import App from './App';
import registerServiceWorker from './registerServiceWorker';


// const piwik = new ReactPiwik({
//     url: 'https://__/',
//     siteId: 2,
//     trackErrors: false,
//     jsFilename: 'matomo.js',
//     phpFilename: 'matomo.php'
// })

// ReactAppzi.initialize('0zhhn');

//piwik.connectToHistory(createHashHistory())

const theme = createMuiTheme({
    typography: {
      fontFamily: "'Roboto' !important",
    },
  });

render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </Provider>,

    document.getElementById('root')
);
registerServiceWorker();
