import i18n from "i18next";
//import Locize from "i18next-locize-backend";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../src/locales/en/translation.json';
import translationDE from '../src/locales/de/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    }
};
const detectionOptions = {
    order: [ 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag', 'path'],
    lookupFromPathIndex: 0

}

const backendOptions = {
    // the id of your locize project
    projectId: 'ffb0bafe-56f0-4b59-a2fa-4cf044060390',

    // add an api key if you want to send missing keys
    apiKey: 'f354d548-a488-4aad-b431-ab0b03b4daae',

    // the reference language of your project
    referenceLng: 'en',

    // private - set to true if you version on locize is set to use private publish
    private: false,

    // hostnames that are allowed to create, update keys
    // please keep those to your local system, staging, test servers (not production)
    // can be array of allowed hosts or a function (hostname) => { return true; // or false if not allowed }
    allowedAddOrUpdateHosts: ['localhost'],

  };


i18n
    .use(detector)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
       // lng: "en",
        fallbackLng: "en", // use en if detected lng is not available
        debug: false,
        detection: detectionOptions,
        saveMissing: true,
       // backend: backendOptions,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    }, (err, t) => {
        if (err)
            console.error(err)
    });
export default i18n;
