import React, { Component } from 'react';
import { NavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
import TransactionsModal from '../../components/DashboardComponent/modals/TransactionsModal';
import DatePicker from 'react-date-picker';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import "./styles.scss";
import i18n from "../../i18n";
import FooterComponent from "../../components/FooterComponent";

class TransactionsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDate: new Date(),
            datePickerOpen: false,
            modalStatus: false,
            isFooterOpen: false,
        }
    }

    onDateChange = date => this.setState({ datePickerOpen: false, selectedDate: date })

    openModal = () => {
        this.setState({ modalStatus: true });
    }

    closeModal = () => {
        this.setState({ modalStatus: false });
    }

    formateDate(date) {
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var monthName = months[date.getMonth()];
        var year = date.getFullYear();
        return monthName + " " + year;
    }

    toggleFooter = () => {
        this.setState({ isFooterOpen: !this.state.isFooterOpen })
    }

    render() {
        return (
            <div className='dashboard-content'>
                <NavBarComponent pageName="transactions" />

                <main className="main-content transactions-page">
                    <div className="transactions-box">
                        <div className="table-widget">
                            <div className="table-header">
                                <div className="left-block">
                                    <TextField
                                        fullWidth
                                        InputProps={{
                                            className: "search-box",
                                            placeholder: i18n.t("Find Transaction Type to search"),
                                            disableUnderline: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div className="right-block">
                                    <span className="txt-title"><DateRangeIcon /> {i18n.t("Date")}: </span>
                                    <span className="txt-value" onClick={() => this.setState({ datePickerOpen: true })}>
                                        {this.state.selectedDate
                                            ? this.formateDate(this.state.selectedDate)
                                            : null}
                                        <ExpandMoreIcon />
                                    </span>
                                    <div className="custom-date-picker">
                                        <DatePicker
                                            isOpen={this.state.datePickerOpen}
                                            onChange={this.onDateChange}
                                            value={this.state.selectedDate}
                                            maxDetail="year"
                                            clearIcon={null}
                                            calendarIcon={null}
                                        />
                                    </div>
                                </div>
                            </div>
                            <table className="table">
                                <tbody>
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CheckIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="4 Aug 1:20 AM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CloseIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="5 Aug 3:00 PM" status="pending" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CheckIcon />} walletAddress="ad9u203rnd9n139nf8" share={-300} date="6 Aug 2:30 AM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CloseIcon />} walletAddress="ad9u203rnd9n139nf8" share={-300} date="7 Aug 10:15 AM" status="pending" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<HourglassEmptyIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="7 Aug 3:45 PM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CloseIcon />} walletAddress="ad9u203rnd9n139nf8" share={-300} date="8 Aug 1:20 PM" status="cancelled" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CheckIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="10 Aug 8:00 AM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CloseIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="12 Aug 7:18 PM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CheckIcon />} walletAddress="ad9u203rnd9n139nf8" share={-300} date="14 Aug 5:20 AM" status="pending" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<HourglassEmptyIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="4 Aug 1:20 AM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CheckIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="5 Aug 3:00 PM" status="pending" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CloseIcon />} walletAddress="ad9u203rnd9n139nf8" share={-300} date="6 Aug 2:30 AM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CloseIcon />} walletAddress="ad9u203rnd9n139nf8" share={-300} date="7 Aug 10:15 AM" status="pending" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<HourglassEmptyIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="7 Aug 3:45 PM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CheckIcon />} walletAddress="ad9u203rnd9n139nf8" share={-300} date="8 Aug 1:20 PM" status="cancelled" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CloseIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="10 Aug 8:00 AM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<HourglassEmptyIcon />} walletAddress="ad9u203rnd9n139nf8" share={1000} date="12 Aug 7:18 PM" status="successfull" />
                                    <TransactionRow openModal={this.openModal} transactionId="h8d73rnb7wt94b8e6x" icon={<CloseIcon />} walletAddress="ad9u203rnd9n139nf8" share={-300} date="14 Aug 5:20 AM" status="pending" />
                                </tbody>
                            </table>
                            <div className="box-shadow"></div>
                        </div>
                    </div>

                    <TransactionsModal handleClose={this.closeModal} open={this.state.modalStatus} />
                {
                    this.state.isFooterOpen
                        ? <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}><CloseIcon /> {i18n.t('Close')}</Button>
                        : <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}>{i18n.t('GTC, Privacy policy & more')}</Button>
                }
                </main>

                {
                    this.state.isFooterOpen ?
                        <div className="footer-block">
                            <FooterComponent />
                        </div>
                        : null
                }
            </div>
        )
    }
}

const TransactionRow = (props) => {
    function checkProfitLoss(value, profitLossCheck = false, conversion = false) {
        var profitLossclassName = "";
        if (profitLossCheck) {
            profitLossclassName = (value > 0) ? 'txt-profit' : 'txt-loss';
        }
        var val = Math.abs(Math.round(Number(value) * 100) / 100);
        if (conversion) {
            var parts = val.toString().split(".");
            val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
        }

        return <span className={profitLossclassName}>{val}</span>;
    }

    return (
        <tr onClick={props.openModal}>
            <td width="60">
                <div className="symbol-icon">
                    {props.icon}
                </div>
            </td>
            <td>
                <p className="txt-symbol">{i18n.t("Symbol")}</p>
                <p className="txt-asset-title">{i18n.t("Asset Title")}</p>
            </td>
            <td>
                <p className="txt-wallet-address">{props.walletAddress}</p>
                <p className="txt-wallet-title">{i18n.t("Wallet address counterparty")}</p>
            </td>
            <td>
                <p className="txt-wallet-address">{props.transactionId}</p>
                <p className="txt-wallet-title">{i18n.t("Transaction Id")}</p>
            </td>
            <td>
                <p className="txt-shares credit">{checkProfitLoss(props.share, false, true)} {i18n.t("Shares")}</p>
                <p className="txt-date">{props.date}</p>
            </td>
            <td width="150">
                <div className="btn-status">
                    <div className={"transaction-status-badge " + props.status}></div> <span>{props.status}</span>
                </div>
            </td>
            <td>
                <Button className="btn-more"><MoreHorizIcon /></Button>
            </td>
        </tr>
    )
}
export default TransactionsPage;
