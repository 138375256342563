import React from 'react';
import red from '@material-ui/core/colors/red';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import "./styles.scss";

const red300 = red['500'];

const style = {
    errorBox: {
        fontSize: '12px',
        color: red300,
        width: 'max-content',
    }
};


class PhoneValidator extends ValidatorComponent {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: props.phoneNumber,
            value: props.value,
            isValid: true
        }

        this.inputRef = React.createRef();
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange(value, data, event) {
        this.setState({
            phoneNumber:value
        })
        if (this.props.onChange) {
            if (!event || !(event.target)) {
                event = {
                    target: this.inputRef.numberInputRef
                }
            }
            event.target.name = this.props.name
            this.props.onChange(event)      
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("prev state", prevState)
    }

    render() {
        const { error, errorMessages, validators, requiredError, helperText, validatorListener, withRequiredValidator, onChange, value, ...rest } = this.props;
        const phoneClass = !this.state.isValid ? 'invalid' : '';

        return (
            <div className={phoneClass}>
                <PhoneInput
                    onChange={this.handleOnChange}
                    value={this.state.phoneNumber}
                    ref={(r) => { this.inputRef = r; }}
                    {...rest}
                /> 
                {this.errorText()}                
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={style.errorBox}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default PhoneValidator;