import React, { Component } from 'react';
import i18n from "../../i18n";
import ReactHtmlParser from 'react-html-parser';
import "./styles.scss";

class HighlightsComponent extends Component {
    render() {
        const { property } = this.props;
        const highlightSection = property.property_sections ? property.property_sections.find((section) => { return section.section_key === 'the_highlights' }) : "";
        const propertySection = property.property_sections ? property.property_sections.find((section) => { return section.section_key === 'the_property' }) : "";

        return (
            <div className="highlights-box">
                {highlightSection && (
                    <div className="section">
                        <h2 className="title-text">{i18n.t(highlightSection.heading)}</h2>
                        <div>{ReactHtmlParser(highlightSection.description)}</div>
                    </div>
                )}
                {propertySection && (
                    <div className="section">
                        <h2 className="title-text">{i18n.t(propertySection.heading)}</h2>
                        <div>{ReactHtmlParser(propertySection.description)}</div>
                    </div>
                )}
            </div>
        )
    }
}

export default HighlightsComponent;