import React, { Component } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReactHtmlParser from 'react-html-parser';
import config from '../../config.json';
import i18n from "../../i18n";
import "./styles.scss";

class InvestmentDetails extends Component {
    formatePrice = (val) => {
        if (val !== undefined && val !== null && !isNaN(val)) {
            var parts = val.toString().split(".");
            if (parts.length > 1) {
                val = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
            }
            val = this.numberWithCommas(val);
            return val;
        }
    }

    numberWithCommas = (x) => {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        return x;
    }

    render() {
        const { property } = this.props;
        const investmentSection = property.property_sections ? property.property_sections.find((section) => {
            return section.section_key === 'the_investment'
        }) : "";

        return (
            <div className='investment-details'>
                {investmentSection && (
                    <div className="section">
                        <h2 className="title-text">{i18n.t(investmentSection.heading)}</h2>
                        <div className="content-text">{ReactHtmlParser(investmentSection.description)}</div>
                    </div>
                )}

                {property && <Card className="financial-detail-box">
                    <CardContent>
                        <table className="table" style={{marginTop: '1.5em'}}>
                            <tbody>
                            {property.property_type === "primary" ? (
                                <tr>
                                    <td><strong>{i18n.t('Financing Volume')}</strong></td>
                                    <td><strong>{this.numberWithCommas(property.financing_volume)} &euro;</strong></td>
                                </tr>
                                ) : (
                                <tr>
                                    <td><strong>{i18n.t('Market Value')}</strong></td>
                                    <td><strong>{this.formatePrice((property.current_price_per_share * property.total_share_count).toFixed(2))} &euro;</strong></td>
                                </tr>
                                )}

                                <tr>
                                    <td><strong>{i18n.t('Forecasted Total Return')} p.a.*</strong></td>
                                    {property.property_type === "primary" ? (
                                    <td><strong>{this.formatePrice((property.profit_payout_percent*1 + property.predicted_value_increase_p_a*1).toFixed(2))} %</strong></td>
                                    ) : (
                                    <td><strong>{this.formatePrice((((property.distribution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2)*1 + property.predicted_value_increase_p_a*1).toFixed(2))} %</strong></td>
                                    )}
                                </tr>





                                <tr>
                                    <td>{i18n.t('financial-detail-box_Expected net rental return')} p.a.*</td>
                                    {property.property_type === "primary" ? (
                                    <td>{this.formatePrice(property.profit_payout_percent)} %</td>
                                    ) : (
                                    <td>{this.formatePrice(((property.distribution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2))} %</td>
                                    )}
                                </tr>



                                <tr>
                                    <td>{i18n.t('Expected value growth')} p.a.*</td>
                                    <td>{this.formatePrice(property.predicted_value_increase_p_a)} %</td>
                                </tr>
                            </tbody>
                        </table>
                    </CardContent>
                </Card>}

                {property && <Grid container spacing={3}>
                    <Grid className="distribution-yield-block" item md={6} xs={12}>
                        <h2 className="txt-title txt-title-secondary">{i18n.t('Expected net rental profit')}*</h2>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td><strong>+ {i18n.t('Net Rental Income')}</strong></td>
                                    <td><strong>{this.formatePrice(property.net_rental_income)} &euro;</strong></td>
                                </tr>
                                <tr>
                                    <td><strong>- {i18n.t('Management Costs')}</strong></td>
                                    <td><strong> {this.formatePrice(property.property_management_cost + property.management_costs + property.rent_protection_cost + property.maintenance_cost)} &euro;</strong></td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Property Management')}</td>
                                    <td>{this.formatePrice(property.property_management_cost)} &euro;</td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Asset Management Fee')}</td>
                                    <td>{this.formatePrice(property.management_costs)} &euro;</td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Loss of rent Reserve')}</td>
                                    <td>{this.formatePrice(property.rent_protection_cost)} &euro;</td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Maintenance Reserve')}</td>
                                    <td>{this.formatePrice(property.maintenance_cost)} &euro;</td>
                                </tr>
                                <tr>
                                    <td><strong>= {i18n.t('Net rental profit')}</strong></td>
                                    <td><strong>{this.formatePrice(property.distribution_surplus)} &euro;</strong></td>
                                </tr>
                                <tr className="list-item">
                                    <td>{i18n.t('Net rental return')}</td>
                                    {property.property_type === "primary" ? (
                                    <td>{property.profit_payout_percent} %</td>
                                    ) :
                                    (
                                        <td>{this.formatePrice(((property.distribution_surplus / (property.current_price_per_share * property.total_share_count)) * 100).toFixed(2))} %</td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h2 className="txt-title txt-title-secondary">{i18n.t('Forecasted Appreciation')}*</h2>
                        <p className="content-text">{property.predicted_value_increase}</p>
                    </Grid>
                </Grid>}

                <p className="content-text">
                    {ReactHtmlParser(property.forecasted_distribution)}
                </p>

                <h2 className="txt-title txt-title-secondary">{i18n.t("Documents")}</h2>
                <Grid className="download-block" container spacing={3}>
                    {property && property.property_documents && property.property_documents.length > 0 &&
                        property.property_documents.map((document, index) => {
                            return (
                                <Grid key={index} item md={4} xs={12}>
                                    <a href={`${config.apiUrl}/download/property/${document.file_path}`} download
                                    ><GetAppIcon /> <span className="doc-name">{document.description}</span></a>
                                </Grid>
                            )
                        })
                    }
                </Grid>

                <h2 className="txt-title txt-title-secondary">{i18n.t('Legal Warnings')}</h2>
                <p className="content-text">
                    {ReactHtmlParser(property.legal_warnings)}
                </p>
            </div>
        )
    }
}

export default InvestmentDetails;
