import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import i18n from "../../i18n";
import logo from "../../assets/images/logo.png";
import "./styles.scss";

class InvetmentFooter extends Component {
    render() {
        const { property, onClick } = this.props;

        return (
            property && (<div className='invetment-footer'>
                {property.property_type === "primary" ? (
                    <Grid container>
                        <Grid item md={2} sm={3} xs={12}>
                            <img src={logo} alt="CrowdEstate Logo" className="footer-logo" />
                        </Grid>
                        <Grid item md={7} sm={5} xs={12}>
                            <p className="property-title">{property.title}</p>
                        </Grid>
                        <Grid className="text-right" item md={3} sm={4} xs={12}>
                            <span className="property-share-value">&euro; {property.price_per_share} / {i18n.t("share_anteil")}</span>
                            <Button onClick={onClick} className="btn-secondary">{i18n.t('Invest Now')}</Button>
                        </Grid>
                    </Grid>
                ) : (
                        <Grid container>
                            <Grid item md={2} sm={3} xs={12}>
                                <img src={logo} alt="CrowdEstate Logo" className="footer-logo" />
                            </Grid>
                            <Grid item md={5} sm={3} xs={12}>
                                <p className="property-title">{property.title}</p>
                            </Grid>
                            <Grid className="text-right" item md={5} sm={6} xs={12}>
                                <span className="property-share-value">{i18n.t('Lowest Available Brick Price')} &euro; {property.lowest_available_brick_price}</span>
                                <Button onClick={onClick} className="btn-secondary">{i18n.t('Create offer now')}</Button>
                            </Grid>
                        </Grid>
                    )}
            </div>)
        )
    }
}

export default InvetmentFooter;
