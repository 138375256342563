import config from './../config.json';
import { authHeader } from '../_helpers';
import { initialState } from '../_reducers/general.reducer'

export const generalService = {
    get_locations,
    getProperty,
    get_all_properties,
    filterProperty,
    verifyPaymentDetails,
};

function get_locations() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${config.apiUrl}/locations`, requestOptions).then(handleResponse);
}

async function getProperty(propertyId) {
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    };
    var initialStateParams = await initialState();

    return fetch(`${config.apiUrl}/property/${propertyId}?lng=${initialStateParams.languageCode}`, requestOptions).then(handleResponse);
}

async function get_all_properties(dataLimit, page) {
    const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    };
    var initialStateParams = await initialState();

    return fetch(`${config.apiUrl}/properties?limit=${dataLimit}&page=${page}&lng=${initialStateParams.languageCode}`, requestOptions).then(handleResponse);
}

async function filterProperty(dataLimit, filterObj, page) {
    var initialStateParams = await initialState();
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            limit: dataLimit,
            page: page,
            lng: initialStateParams.languageCode,
            filterQuery: filterObj
        })
    };

    return fetch(`${config.apiUrl}/properties/filter`, requestOptions).then(handleResponse);
}


function verifyPaymentDetails(propertyId, investmentDetailObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            investment_detail: investmentDetailObj,
            propertyId
        })
    };

    return fetch(`${config.apiUrl}/property/verify-payment-detail`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        try {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        }
        catch (e) {
            return Promise.reject("Not Found");
        }
    });
}
