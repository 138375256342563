import config from './../config.json';
import { authHeader } from '../_helpers';

export const userService = {
    login,
    logout,
    register,
    getUserDetails,
    updateUserProfile,
    getSecurityQuestion,
    addUserInvestment,
    getUserInvestment,
    getUserCommittedInvestment,
    getUserLatestInvestment,
    getUserPayments,
    getSettings,
    addUserOrder,
    getUserRecentTransactions,
    getUsersOrders,
    getUserOrders,
    deleteUserOrder,
    verifyUserShares,
    getUserUnrealisedProfit,
    getUserRealisedProfitData,
    getUserRealisedProfit
};

function login(email_id, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email_id, password })
    };

    return fetch(`${config.apiUrl}/login`, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.success) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(config.CONSTANT.LOGIN_KEY, JSON.stringify(result.data.token));
                return result.data.token;
            }
            else {
                return false;
            }
        });
}


function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/register`, requestOptions).then(handleResponse);
}


function logout() {
    localStorage.removeItem(config.CONSTANT.LOGIN_KEY); // remove user from local storage to log user out
    window.location = "/login";
}


function getUserDetails() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/profile`, requestOptions).then(handleResponse);
}


function updateUserProfile(updateObj) {
    var formData = new FormData();
    if (updateObj) {
        Object.keys(updateObj).forEach(function (key) {
            if (key !== 'profile_preview') {
                if (key === 'security_questions') {
                    var security_questions = JSON.stringify(updateObj[key]);
                    formData.append(key, security_questions);
                } else {
                    formData.append(key, updateObj[key]);
                }
            }
        });
    }
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader() },
        body: formData
    };

    return fetch(`${config.apiUrl}/profile`, requestOptions).then(handleResponse)
}


function getSecurityQuestion() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/profile/security-question`, requestOptions).then(handleResponse);
}


function addUserInvestment(dataObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(dataObj)
    };

    return fetch(`${config.apiUrl}/user/investment`, requestOptions).then(handleResponse);
}


function getUserInvestment() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/user/investment`, requestOptions).then(handleResponse);
}

function getUserCommittedInvestment() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/user/committed-investment`, requestOptions).then(handleResponse);
}

function getUserLatestInvestment() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${config.apiUrl}/user/latest-investment`, requestOptions).then(handleResponse);
}

function getUserPayments() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${config.apiUrl}/user/payments`, requestOptions).then(handleResponse);
}


function getSettings() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/settings`, requestOptions).then(handleResponse);
}

function addUserOrder(orderDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(orderDetails)
    };

    return fetch(`${config.apiUrl}/user/order`, requestOptions).then(handleResponse);
}

function getUserRecentTransactions(propertyId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/user/recent-transaction?propertyId=${propertyId}`, requestOptions).then(handleResponse);
}

function getUsersOrders(propertyId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/users/orders?propertyId=${propertyId}`, requestOptions).then(handleResponse);
}

function getUserOrders() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/user/orders`, requestOptions).then(handleResponse);
}

function deleteUserOrder(orderDetails) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(orderDetails)
    };

    return fetch(`${config.apiUrl}/user/order-delete`, requestOptions).then(handleResponse)
}


function verifyUserShares(propertyId, investmentDetailObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({
            investment_detail: investmentDetailObj,
            propertyId
        })
    };

    return fetch(`${config.apiUrl}/user/verify-shares`, requestOptions).then(handleResponse);
}

function getUserUnrealisedProfit() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/user/unrealised-profit`, requestOptions).then(handleResponse);
}

function getUserRealisedProfit() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/user/realised-profit`, requestOptions).then(handleResponse);
}

function getUserRealisedProfitData() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${config.apiUrl}/user/realised-profit-chart`, requestOptions).then(handleResponse);
}






function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

