import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import { Container } from '@material-ui/core';
import { history } from '../src/_helpers';
import { PrivateRoute } from '../src/_components';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import HomePage from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';
import { RegistrationPage } from './pages/RegistrationPage';
import { SearchPage } from './pages/SearchPage';
import { PropertyDetailPage } from './pages/PropertyDetailsPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import InvestmentWalletPage from './pages/Dashboard/InvestmentWalletPage';
import ProfilePage from './pages/Dashboard/ProfilePage';
import PaymentsPage from './pages/Dashboard/PaymentsPage';
import OrdersPage from './pages/Dashboard/Orders';
import TransactionsPage from './pages/Dashboard/TransactionsPage';
import "./styles.scss";

export default class App extends Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            window.scrollTo(0, 0)
        });

        this.state = {
            logopath: this.getQueryVariable('logopath'),
            pagebackground: this.getQueryVariable('pagebackground'),
            logobackground: this.getQueryVariable('logobackground')
        }
    }


    getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }


    render() {
        return (
            <Container maxWidth={false} className="no-padding">
                <Router history={history}>
                    <Switch>
                        <PrivateRoute path="/dashboard/orders" component={OrdersPage} />
                        <PrivateRoute path="/dashboard/payments" component={PaymentsPage} />
                        <PrivateRoute path="/dashboard/transactions" component={TransactionsPage} />
                        <PrivateRoute path="/dashboard/wallet" component={InvestmentWalletPage} />
                        <PrivateRoute path="/dashboard/profile" component={ProfilePage} />
                        <PrivateRoute path="/dashboard" component={DashboardPage} />

                        <Route path="/property/view/:id">
                            <HeaderComponent logopath={this.state.logopath} logobackground={this.state.logobackground} />
                            <PropertyDetailPage />
                        </Route>
                        <Route path="/marketplace">
                            <div className="fixed">
                                <HeaderComponent  logopath={this.state.logopath}  logobackground={this.state.logobackground}/>
                            </div>
                            <SearchPage />
                        </Route>
                        <Route path="/login">
                            <HeaderComponent logopath={this.state.logopath}  logobackground={this.state.logobackground}/>
                            <LoginPage />
                            <FooterComponent />
                        </Route>
                        <Route path="/register">
                            <HeaderComponent logopath={this.state.logopath}  logobackground={this.state.logobackground}/>
                            <RegistrationPage />
                            <FooterComponent />
                        </Route>
                        <Route exact path="/">
                            <HeaderComponent logopath={this.state.logopath}  logobackground={this.state.logobackground}/>
                            <HomePage background={this.state.pagebackground}/>
                            <FooterComponent />
                            {/* <div className="fixed">
                                <HeaderComponent />
                            </div>
                            <SearchPage /> */}
                        </Route>
                    </Switch>
                </Router>
            </Container >
        );
    }
}
