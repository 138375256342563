import React from "react";
import { NavLink } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import SpeedOutlinedIcon from '@material-ui/icons/SpeedOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import ListIcon from '@material-ui/icons/List';
// import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
// import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import "../styles.scss";
import i18n from "../../../i18n";


export default function NavBarLinks(props) {
  const { navControl, page } = props;

  function getActiveMenu(pageName) {
    return (pageName === page) ? "nav-link active" : "nav-link";
  }

  return (
    <List disablePadding className="nav-link-bar">
      <ListItem className={getActiveMenu("dashboard")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><SpeedOutlinedIcon /></ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard" className="nav-text">{i18n.t('Dashboard')}</NavLink>
        </ListItemText>
      </ListItem>
      <ListItem className={getActiveMenu("investment-wallet")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><WorkOutlineOutlinedIcon /></ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/wallet" className="nav-text">{i18n.t('Investment Wallet')}</NavLink>
        </ListItemText>
      </ListItem>
      <ListItem className={getActiveMenu("payments")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><PaymentOutlinedIcon /></ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/payments" className="nav-text">{i18n.t('Payments')}</NavLink>
        </ListItemText>
      </ListItem>
      <ListItem className={getActiveMenu("orders")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><ListIcon /></ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/orders" className="nav-text">{i18n.t('My Orders')}</NavLink>
        </ListItemText>
      </ListItem>
      {/* <ListItem className={getActiveMenu("transactions")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><ReceiptOutlinedIcon /></ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/transactions" className="nav-text">Transactions</NavLink>
        </ListItemText>
      </ListItem> */}
      {/* <ListItem className={getActiveMenu("reports")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><TrendingUpOutlinedIcon /></ListItemIcon>
        <ListItemText>
          <Link to="/dashboard" onClick={navControl} className="nav-text">Reports</Link>
        </ListItemText>
      </ListItem> */}
      <ListItem className={getActiveMenu("profile")} button onClick={navControl}>
        <ListItemIcon className="nav-icon"><FaceOutlinedIcon /></ListItemIcon>
        <ListItemText>
          <NavLink to="/dashboard/profile" onClick={navControl} className="nav-text">{i18n.t('Profile')}</NavLink>
        </ListItemText>
      </ListItem>
    </List>
  );
}
