import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    progress: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)'
    },
}));

export default function Loader() {
    const classes = useStyles();

    return (
        <div className={classes.progress}>
            <CircularProgress color="secondary" />
        </div>
    );
}