import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Chart from "./../../utils/chart";

class SmallStats extends React.Component {
    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();
    }
    componentDidMount() {
        const chartOptions = {
            ...{
                maintainAspectRatio: true,
                responsive: true,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                    custom: false
                },
                elements: {
                    point: {
                        radius: 0
                    },
                    line: {
                        tension: (this.props.variation === "1") ? 0 : 0.33
                    }
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: false,
                            ticks: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            gridLines: false,
                            scaleLabel: false,
                            ticks: {
                                display: false,
                                isplay: false,
                                suggestedMax: Math.max(...this.props.chartData[0].data) + 1
                            }
                        }
                    ]
                }
            },
            ...this.props.chartOptions
        };

        const chartConfig = {
            ...{
                type: "line",
                data: {
                    ...{
                        labels: this.props.chartLabels
                    },
                    ...{
                        datasets: this.props.chartData
                    }
                },
                options: chartOptions
            },
            ...this.props.chartConfig
        };

        new Chart(this.canvasRef.current, chartConfig);
    }

    render() {
        const { variation } = this.props;

        const cardClasses = classNames(
            "stats-small",
            variation && `stats-small--${variation}`
        );

        const cardBodyClasses = classNames(
            variation === "1" ? "p-0 d-flex" : "px-0 pb-0"
        );

        const canvasHeight = variation === "1" ? 180 : 70;

        return (
            <div className={cardClasses}>
                <div className={cardBodyClasses}>
                    <canvas
                        height={canvasHeight}
                        ref={this.canvasRef}
                    />
                </div>
            </div>
        );
    }
}

SmallStats.propTypes = {
    /**
     * The Small Stats variation.
     */
    variation: PropTypes.string,
    /**
     * The label.
     */
    label: PropTypes.string,
    /**
     * The value.
     */
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The percentage number or string.
     */
    percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Whether is a value increase, or not.
     */
    increase: PropTypes.bool,
    /**
     * The Chart.js configuration object.
     */
    chartConfig: PropTypes.object,
    /**
     * The Chart.js options object.
     */
    chartOptions: PropTypes.object,
    /**
     * The chart data.
     */
    chartData: PropTypes.array.isRequired,
    /**
     * The chart labels.
     */
    chartLabels: PropTypes.array
};

SmallStats.defaultProps = {
    increase: true,
    percentage: 0,
    value: 0,
    label: "Label",
    chartOptions: Object.create(null),
    chartConfig: Object.create(null),
    chartData: [],
    chartLabels: []
};

export default SmallStats;
