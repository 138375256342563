  const headerStyle = theme => ({
    appBar: {
      display: "flex",
      border: "0",
      borderRadius: "0",
      padding: "0.625rem 0",
      marginBottom: "0",
      height: "70px",
      color: "#000",
      width: "100%",
      backgroundColor: "#FFF",
      boxShadow: "none",
      transition: "all 150ms ease 0s",
      alignItems: "center",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
      position: "relative",
      zIndex: "1100"
    },
    absolute: {
      position: "absolute",
      zIndex: "1100"
    },
    fixed: {
      position: "fixed",
      zIndex: "1100"
    },
    container: {
      minHeight: "50px",
      flex: "1",
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      flexWrap: "nowrap",
    },
    flex: {
      flex: 1
    },
    title: {
      lineHeight: "30px",
      fontSize: "28px",
      borderRadius: "3px",
      textTransform: "none",
      color: "inherit",
      padding: "8px 16px",
      letterSpacing: "unset",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent"
      }
    },
    iconColor: {
      color: "#889aae"
    },
    navLinkActive: {
        color: "inherit",
        background: "#f9fafc"
    },
    appResponsive: {
      margin: "20px 10px"
    },
    primary: {
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)"
    },
    transparent: {
      backgroundColor: "transparent !important",
      boxShadow: "none",
      paddingTop: "25px",
      color: "#FFFFFF"
    },
    dark: {
      color: "#FFFFFF",
      backgroundColor: "#212121 !important",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
    },
    white: {
      border: "0",
      padding: "0.625rem 0",
      marginBottom: "20px",
      color: "#555",
      backgroundColor: "#fff !important",
      boxShadow:
        "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)"
    },
    appDrawerPaper: {
      border: "none",
      borderRight: "3px solid #f4f5f7",
      bottom: "0",
      backgroundColor: "#FFF",
      color: "#FFF",
      transitionProperty: "top, bottom, width",
      transitionDuration: ".2s, .2s, .35s",
      transitionTimingFunction: "linear, linear, ease",
      width: 260,
      position: "fixed",
      display: "block",
      top: "70px",
      height: "100%",
      right: "right",
      left: "0",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      zIndex: "-1",
      "@media (max-width: 768px)": {
        top: "0px",
      },
    },
    navBarTitle: {
      height: "70px",
      textAlign: "center",
      padding: '15px 0',
      backgroundColor: "#FFF"
    },
    drawerPaper: {
      border: "none",
      bottom: "0",
      transitionProperty: "top, bottom, width",
      transitionDuration: ".2s, .2s, .35s",
      transitionTimingFunction: "linear, linear, ease",
      width: 260,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
    },
    list: {
      fontSize: "14px",
      margin: 0,
      paddingLeft: "0",
      listStyle: "none",
      paddingTop: "0",
      paddingBottom: "0",
      color: "inherit"
    },
    listItem: {
      float: "left",
      color: "inherit",
      position: "relative",
      display: "block",
      width: "auto",
      margin: "0",
      padding: "0",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        "&:after": {
          // width: "calc(100% - 30px)",
          // content: '""',
          // display: "block",
          // height: "1px",
          // marginLeft: "15px",
          // backgroundColor: "#e5e5e5"
        }
      }
    },
    listItemText: {
      padding: "0 !important"
    },
    navLink: {
      color: "inherit",
      position: "relative",
      padding: "0.9375rem",
      fontWeight: "500",
      fontSize: "14px",
      textTransform: "uppercase",
      borderRadius: "3px",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex",
      "&:hover,&:focus": {
        color: "inherit",
        background: "rgba(0, 0, 0, 0.1)"
      },
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 30px)",
        marginLeft: "15px",
        marginBottom: "8px",
        marginTop: "8px",
        textAlign: "left",
        "& > span:first-child": {
          justifyContent: "flex-start"
        }
      }
    },
    navBarDarkLink: {
      color: "#333",
      display: "block",
      width: "100%",
      lineHeight: "2em",
      "&:hover,&:focus": {
        color: "inherit"
      },
    },
    navBarLink: {
      color: "#262632 !important",
      display: "block",
      "&:hover,&:focus": {
        color: "inherit"
      },
    },
    notificationNavLink: {
      color: "inherit",
      padding: "0.9375rem",
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex",
      top: "4px"
    },
    registerNavLink: {
      top: "3px",
      position: "relative",
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex"
    },
    icons: {
      width: "20px",
      height: "20px",
      marginRight: "3px"
    },
    socialIcons: {
      position: "relative",
      fontSize: "20px !important",
      marginRight: "4px"
    },
    dropdownLink: {
      "&,&:hover,&:focus": {
        color: "inherit",
        textDecoration: "none",
        display: "block",
        padding: "10px 20px"
      }
    },
    marginRight5: {
      marginRight: "5px"
    },
    navLinks: {
      backgroundColor: "#FFF"
    },
    navDrawerImg: {
      width: "120px",
      margin: "10px auto"
    },
    toolbar: {
      textAlign: "center",
      padding: "20px 15px"
    }
  });
  
  export default headerStyle;
  