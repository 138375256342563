import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button, Grid, LinearProgress, InputAdornment, TextField } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountUp, faSortAmountDown } from '@fortawesome/free-solid-svg-icons'
import { userActions, alertActions } from '../../_actions';
import { history } from '../../_helpers/history';
import NotificationBox from '../../components/GeneralComponent/NotificationBox';
import { NavBarComponent } from '../../components/DashboardComponent/common/NavBarComponent';
import InvestmentModal from '../../components/DashboardComponent/modals/InvestmentModal';
import OrderBookModal from '../../components/DashboardComponent/modals/OrderBookModal';
import OverviewWidgetBlock1 from '../../components/DashboardComponent/OverviewWidgetBlock1';
import FooterComponent from "../../components/FooterComponent";
import 'react-table/react-table.css';
import "./styles.scss";
import i18n from "../../i18n";

let portfolioDataObj = {
    chartLabels: [null, null, null, null, null, null, null, null, null, null, null, null, null, null],
    datasets: [
        {
            borderWidth: 1.5,
            backgroundColor: "rgba(108, 210, 48, 0.1)",
            borderColor: "rgb(108, 210, 48)",
            data: [2, 3, 4, 6, 5, 5, 6, 8, 6, 7, 8, 5, 6, 5, 8]
        }
    ]
};

class InvestmentWalletPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalStatus: false,
            isUserLoggedIn: false,
            SelectedInvestment: {},
            AllCommittedInvestmentsData: [],
            allCommittedInvestmentsorted: [],
            AllInvestmentsData: [],
            allInvestmentsorted: [],
            formatAllInvestmentData: this.formatAllInvestmentData,
            formatAllCommittedInvestmentData: this.formatAllCommittedInvestmentData,
            isFooterOpen: false,
            portfolioAmount: 0,
            portfolioChangeAmount: 0,
            portfolioChangePercentage: 0,
        };
        this.getInvestmentSortedComponent = this.getInvestmentSortedComponent.bind(this);
        this.getCommittedInvestmentSortedComponent = this.getCommittedInvestmentSortedComponent.bind(this);
    }

    componentDidMount() {
        this.props.clearAlerts();
        this.props.getUserInvestment();
        this.props.getUserCommittedInvestment();
        this.props.getUserUnrealisedProfit()

        var tokenInfo = this.props.parseJwt();
        if (tokenInfo && tokenInfo.status) {
            this.setState({ isUserLoggedIn: true });
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { investment_data, committed_investment_data, unrealised_profit } = props.users;

        let update = {}
        if (investment_data && investment_data.status) {
            update = {
                ...update,
                AllInvestmentsData: state.formatAllInvestmentData(investment_data.data)
            }
        }

        if (committed_investment_data && committed_investment_data.status) {
            update = {
                ...update,
                AllCommittedInvestmentsData: state.formatAllCommittedInvestmentData(committed_investment_data.data)
            }
        }

        if (unrealised_profit && unrealised_profit.status) {
            update = {
                ...update,
                portfolioAmount: unrealised_profit.data.portfolio_amount,
                portfolioChangeAmount: unrealised_profit.data.portfolio_change_amount,
                portfolioChangePercentage: unrealised_profit.data.portfolio_change_percentage,
            }
        }

        return update;
    }

    calculateProfitAmount = (price_per_share, market_price_per_share, token_quantity) => {
        var userInvestmentAmount = price_per_share * token_quantity;
        var investmentAmountAsPerMarketValue = market_price_per_share * token_quantity;
        var userProfileLossAmount = (investmentAmountAsPerMarketValue - userInvestmentAmount).toFixed(2);
        return userProfileLossAmount;
    }

    calculateProfitPercentage = (price_per_share, market_price_per_share) => {
        var grossProfit = market_price_per_share - price_per_share;
        var prsntg = (grossProfit / price_per_share * 100).toFixed(2);
        return prsntg;
    }

    formatAllInvestmentData = (investmentData) => {
        if (investmentData.length > 0) {
            var userInvestmentArray = [];
            investmentData.forEach((iData) => {
                userInvestmentArray.push({
                    id: iData.id,
                    name_symbol: iData.property_master.title + ' (' + iData.property_master.token_name + ')',
                    price_share: iData.property_master.current_price_per_share,
                    chg1y: 1.53,
                    chg1m: 0.20,
                    shares: iData.token_quantity,
                    value: iData.investment_amount,
                    profit_loss_1: this.calculateProfitAmount(iData.price_per_share, iData.property_master.current_price_per_share, iData.token_quantity),
                    profit_loss_2: this.calculateProfitPercentage(iData.price_per_share, iData.property_master.current_price_per_share),
                    property_master: iData.property_master,
                    property_issuer: iData.property_issuer,
                    investment_type: 'all_investment'
                })
            })
            return userInvestmentArray
        }
        return [];
    }

    formatAllCommittedInvestmentData = (committedInvestmentData) => {
        if (committedInvestmentData.length > 0) {
            return committedInvestmentData.map((singleCommittedInvestment) => {
                return {
                    id: singleCommittedInvestment.id,
                    name_symbol: singleCommittedInvestment.property_master.title + ' (' + singleCommittedInvestment.property_master.token_name + ')',
                    price_share: singleCommittedInvestment.price_per_share,
                    shares: singleCommittedInvestment.amount,
                    committed_investment: singleCommittedInvestment.investment_amount,
                    funding_process: singleCommittedInvestment.property_funding_percentage,
                    property_master: singleCommittedInvestment.property_master,
                    property_issuer: singleCommittedInvestment.property_issuer,
                    investment_type: 'committed_investment'

                }
            })
        }
        return [];
    }

    //007 - update carefully, look into working flow three times before change code
    openInvestmentModal = (selectedInvestment) => {
        this.setState({
            SelectedInvestment: selectedInvestment
        }, () => {
            const { isUserLoggedIn } = this.state;
            if (isUserLoggedIn) {
                this.props.getUserDetails();
                this.setState({ modalStatus: true });
            } else {
                history.push('/login', { next: `/dashboard/wallet` })
            }
        })
    }

    closeInvestmentModal = () => {
        this.setState({ modalStatus: false });
    }

    //007 - update carefully, look into working flow three times before change code
    openOrderBookModal = (selectedInvestment) => {
        this.setState({
            SelectedInvestment: selectedInvestment
        }, () => {
            const { isUserLoggedIn, SelectedInvestment } = this.state;
            if (isUserLoggedIn) {
                const propertyDetails = (SelectedInvestment && SelectedInvestment.property_master) ? SelectedInvestment.property_master : {};
                const propertyId = propertyDetails.property_id;
                if (propertyId) {
                    this.props.getUserDetails();
                    this.props.getSettings();
                    this.props.getUsersOrders(propertyId);
                    this.setState({ modalStatus: true });
                }
            } else {
                history.push('/login', { next: `/dashboard/wallet` })
            }
        })
    }

    getInvestmentSortedComponent(id) {
        let sortInfo = this.state.allInvestmentsorted.filter(item => item.id === id);
        if (sortInfo.length) {
            if (sortInfo[0].desc === true) return <FontAwesomeIcon icon={faSortAmountDown} />;
            if (sortInfo[0].desc === false) return <FontAwesomeIcon icon={faSortAmountUp} />;
        }
        return <FontAwesomeIcon icon={faSortAmountDown} />;
    }

    getCommittedInvestmentSortedComponent(id) {
        let sortInfo = this.state.allCommittedInvestmentsorted.filter(item => item.id === id);
        if (sortInfo.length) {
            if (sortInfo[0].desc === true) return <FontAwesomeIcon icon={faSortAmountDown} />;
            if (sortInfo[0].desc === false) return <FontAwesomeIcon icon={faSortAmountUp} />;
        }
        return <FontAwesomeIcon icon={faSortAmountDown} />;
    }

    checkProfitLoss(value, profitLossCheck = false, conversion = false, symbol = "") {
        var profitLossclassName = "";
        if (profitLossCheck) {
            profitLossclassName = (value > 0) ? 'txt-profit' : 'txt-loss';
        }
        if (conversion) {
            var parts = value.toString().split(".");
            value = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (parts[1] ? "," + parts[1] : "");
        }

        return <span className={profitLossclassName}>{value} {symbol}</span>;
    }

    toggleFooter = () => {
        this.setState({ isFooterOpen: !this.state.isFooterOpen })
    }

    render() {
        const { getInvestmentSortedComponent, getCommittedInvestmentSortedComponent } = this;
        const { allInvestmentsorted, allCommittedInvestmentsorted } = this.state;
        const { alert } = this.props;
        const { isUserLoggedIn } = this.state;
        const SelectedInvestment = this.state.SelectedInvestment;
        const propertyDetails = (SelectedInvestment && SelectedInvestment.property_master) ? SelectedInvestment.property_master : {};
        const propertyIssuerDetail = (SelectedInvestment && SelectedInvestment.property_issuer) ? SelectedInvestment.property_issuer : {};
        propertyDetails.issuer_details = propertyIssuerDetail;
        const isValidInvestmentDetail = SelectedInvestment && Object.keys(SelectedInvestment).length > 0;

        const genericHeaderArrows = () => {
            return {
                Header: props => {
                    const Sorted = getCommittedInvestmentSortedComponent(props.column.id);
                    return (<span>{props.column.headerText} {Sorted}</span>);
                },
                headerStyle: { boxShadow: "none" }
            };
        };
        const genericHeaderArrows2 = () => {
            return {
                Header: props => {
                    const Sorted = getInvestmentSortedComponent(props.column.id);
                    return (<span>{props.column.headerText} {Sorted}</span>);
                },
                headerStyle: { boxShadow: "none" }
            };
        };

        const AllCommittedInvestmentsColumns = [{
            ...genericHeaderArrows(),
            headerText: i18n.t("Name & Symbol"),
            accessor: 'name_symbol'
        }, {
            ...genericHeaderArrows(),
            headerText: i18n.t("Price / Share"),
            accessor: 'price_share'
        }, {
            ...genericHeaderArrows(),
            headerText: i18n.t("Shares"),
            width: 80,
            accessor: 'shares',
            Cell: props => <p className="text-right">{props.value}</p>
        }, {
            ...genericHeaderArrows(),
            headerText: i18n.t("Committed Investment"),
            accessor: 'committed_investment',
            width: 180,
            Cell: props => <p className="text-right">{props.value}</p>
        }, {
            ...genericHeaderArrows(),
            headerText: i18n.t("Funding process"),
            accessor: 'funding_process',
            Cell: props => <CustomLinearProgress progress={props.value} />
        }, {
            headerText: '',
            accessor: 'id',
            width: 90,
            Cell: props => <Button className="btn-buy" onClick={(e) => { this.openInvestmentModal(props.original); e.stopPropagation(); }}>Buy</Button>
        }];

        const AllInvestmentsColumns = [{
            ...genericHeaderArrows2(),
            headerText: i18n.t("Name & Symbol"),
            accessor: 'name_symbol',
            width: 200,
        }, {
            ...genericHeaderArrows2(),
            headerText: i18n.t("Price / Share"),
            accessor: 'price_share',
            width: 150,
        },
        // {
        //     ...genericHeaderArrows2(),
        //     headerText: i18n.t("Chg 1Y"),
        //     accessor: 'chg1y',
        //     width: 80,
        //     Cell: props => <p className="text-right">{this.checkProfitLoss(props.value, true)}</p>
        // }, {
        //     ...genericHeaderArrows2(),
        //     headerText: i18n.t("Chg 1M"),
        //     accessor: 'chg1m',
        //     width: 100,
        //     Cell: props => <p className="text-right">{this.checkProfitLoss(props.value, true)}</p>
        // },
        {
            ...genericHeaderArrows2(),
            headerText: i18n.t("Shares"),
            accessor: 'shares',
            width: 150,
            Cell: props => <p className="no-margin">{props.value}</p>
        }, {
            ...genericHeaderArrows2(),
            headerText: i18n.t("Value"),
            accessor: 'value',
            width: 150,
            Cell: props => <p className="no-margin">{this.checkProfitLoss(props.value, false, true)}</p>
        }, {
            ...genericHeaderArrows2(),
            headerText: i18n.t("Profit / Loss"),
            accessor: 'profit_loss_1',
            width: 150,
            Cell: props => <p className="no-margin">{this.checkProfitLoss(props.value, true, true, "€")}</p>
        },
        {
            ...genericHeaderArrows2(),
            headerText: i18n.t("Profit / Loss"),
            accessor: 'profit_loss_2',
            width: 150,
            Cell: props => <p className="no-margin">{this.checkProfitLoss(props.value, true, true, "%")}</p>
        },
        {
            headerText: '',
            accessor: 'id',
            width: 140,
            Cell: props => <Button className="btn-buy" onClick={(e) => { this.openOrderBookModal(props.original); e.stopPropagation(); }}>{i18n.t('Buy / Sell')}</Button>
        }]

        return (
            <div className='dashboard-content'>
                <NavBarComponent pageName="investment-wallet" />

                <main className="main-content investment-wallet-page">
                    {alert.message && <NotificationBox open={true} variant={alert.type} message={alert.message} />}
                    <p className="widget-block-title">{i18n.t('All Committed Investments')}</p>

                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <div className="table-widget">
                                <div className="table-header">
                                    <div className="left-block">
                                        {/* <TextField
                                            fullWidth
                                            InputProps={{
                                                className: "search-box",
                                                placeholder: i18n.t("Find Your Asset Type to search"),
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        /> */}
                                    </div>
                                    <div className="right-block">
                                        <CreditCardOutlinedIcon /> <span className="txt-currency">{i18n.t("Currency")}</span> <span className="txt-currency-name">{i18n.t('EUR')}</span>
                                    </div>
                                </div>
                                <Scrollbars style={{ height: 200 }} autoHide autoHideTimeout={1000}>
                                    <ReactTable
                                        data={this.state.AllCommittedInvestmentsData}
                                        columns={AllCommittedInvestmentsColumns}
                                        showPagination={false}
                                        resizable={false}
                                        sortable={true}
                                        sorted={allCommittedInvestmentsorted}
                                        onSortedChange={sorted => this.setState({ allCommittedInvestmentsorted: sorted })}
                                        getTdProps={(state, row) => {
                                            return {
                                                onClick: (e, handleOriginal) => {
                                                    this.props.history.push("/property/view/" + row.original.property_master.property_id)
                                                    if (handleOriginal) {
                                                        handleOriginal()
                                                    }
                                                }
                                            }
                                        }}
                                        minRows={0}
                                    />
                                </Scrollbars>

                                <div className="table-box-shadow"></div>
                            </div>
                        </Grid>
                        {/* <Grid item md={3} xs={12}>
                            <div className="widget-block">
                                <OverviewWidgetBlock1
                                    chartData={portfolioDataObj.datasets}
                                    chartLabels={portfolioDataObj.chartLabels}
                                    type={1}
                                    variation={"1"}
                                    value={this.state.portfolioAmount}
                                    percentage={this.state.portfolioChangePercentage}
                                    block_text={i18n.t('Portfolio value')}
                                    percentage_value={this.state.portfolioChangeAmount} />


                            </div>
                        </Grid> */}
                    </Grid>


                    <p className="widget-block-title">{i18n.t('All Investments')}</p>

                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <div className="table-widget all-investments-block">
                                <div className="table-header">
                                    <div className="left-block">
                                        {/* <TextField
                                            fullWidth
                                            InputProps={{
                                                className: "search-box",
                                                placeholder: i18n.t("Find Your Asset Type to search"),
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        /> */}
                                    </div>
                                    <div className="right-block">
                                        <CreditCardOutlinedIcon /> <span className="txt-currency">{i18n.t("Currency")}</span> <span className="txt-currency-name">{i18n.t('EUR')}</span>
                                    </div>
                                </div>
                                <Scrollbars style={{ height: 300 }} autoHide autoHideTimeout={1000}>
                                    <ReactTable
                                        data={this.state.AllInvestmentsData}
                                        columns={AllInvestmentsColumns}
                                        showPagination={false}
                                        resizable={false}
                                        sortable={true}
                                        sorted={allInvestmentsorted}
                                        onSortedChange={sorted => this.setState({ allInvestmentsorted: sorted })}
                                        getTdProps={(state, row) => {
                                            return {
                                                onClick: (e, handleOriginal) => {
                                                    console.log(row)
                                                    this.props.history.push("/property/view/" + row.original.property_master.property_id);
                                                    if (handleOriginal) {
                                                        handleOriginal()
                                                    }
                                                }
                                            }
                                        }}
                                        minRows={0}
                                    />
                                </Scrollbars>

                                <div className="table-box-shadow"></div>
                            </div>
                        </Grid>
                    </Grid>

                    {(isUserLoggedIn && isValidInvestmentDetail) && (
                        propertyDetails.property_type === "primary" ? (
                            <InvestmentModal
                                handleClose={this.closeInvestmentModal}
                                open={this.state.modalStatus}
                                property={propertyDetails}
                                propertyId={propertyDetails.property_id}
                            />
                        ) : (
                                <OrderBookModal
                                    handleClose={this.closeInvestmentModal}
                                    open={this.state.modalStatus}
                                    property={propertyDetails}
                                />
                            ))
                    }
                    {
                        this.state.isFooterOpen
                            ? <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}><CloseIcon /> {i18n.t('Close')}</Button>
                            : <Button variant="contained" className="btn-footer-action" onClick={this.toggleFooter}>{i18n.t('GTC, Privacy policy & more')}</Button>
                    }
                </main>

                {
                    this.state.isFooterOpen ?
                        <div className="footer-block">
                            <FooterComponent />
                        </div>
                        : null
                }
            </div>
        )
    }
}



const CustomLinearProgress = (props) => {
    var progressClassName = (props.progress > 50) ? "funding-progress progress-success" : (props.progress > 20) ? "funding-progress progress-info" : "funding-progress progress-danger";

    return (
        <LinearProgress className={progressClassName} variant="determinate" value={props.progress} />
    );
}




function mapState(state) {
    const { users, alert } = state;
    return { users, alert };
}

const actionCreators = {
    getUserInvestment: userActions.getUserInvestment,
    getUserCommittedInvestment: userActions.getUserCommittedInvestment,
    clearAlerts: alertActions.clear,
    parseJwt: userActions.parseJwt,
    getUsersOrders: userActions.getUsersOrders,
    getUserDetails: userActions.getUserDetails,
    getSettings: userActions.getSettings,
    getUserUnrealisedProfit: userActions.getUserUnrealisedProfit
}

export default connect(mapState, actionCreators)(InvestmentWalletPage);
