import { generalConstants } from '../_constants';
import { generalService } from '../_services';
import { alertActions } from './';


function getAllLocations() {
    return dispatch => {
        generalService.get_locations()
            .then(
                locations => dispatch(success(locations))
            );
    };

    function success(locations) { return { type: generalConstants.GET_LOCATION_SUCCESS, locations } }
}

function getProperty(propertyId) {
    return dispatch => {
        generalService.getProperty(propertyId)
            .then(
                properties => dispatch(success(properties))
            );
    };

    function success(properties) { return { type: generalConstants.GET_PROPERTIES_SUCCESS, properties } }
}

function getAllProperties(dataLimit, page) {
    return dispatch => {
        generalService.get_all_properties(dataLimit, page)
            .then(
                properties => {
                    if (properties.status) {
                        dispatch(success(properties))
                    }
                    else {
                        dispatch(success(properties))
                        //dispatch(alertActions.error(properties.message.toString()));
                    }
                }
            );
    };

    function success(properties) { return { type: generalConstants.GET_ALL_PROPERTIES_SUCCESS, properties } }
}

const filterProperty = (dataLimit, filterObj, page) => dispatch => {
    return new Promise(function (resolve, reject) {
        generalService.filterProperty(dataLimit, filterObj, page)
            .then(
                properties => {
                    if (properties.status) {
                        dispatch(success(properties));
                        resolve('resolved');
                    }
                    else {
                        dispatch(success(properties))
                        //dispatch(alertActions.error(properties.message.toString()));
                        resolve('resolved');
                    }
                },
                error => {
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    });

    function success(properties) { return { type: generalConstants.GET_ALL_PROPERTIES_SUCCESS, properties } }
}

const verifyPaymentDetails = (propertyId, investmentDetailObj) => dispatch => {
    return new Promise(function (resolve, reject) {
        generalService.verifyPaymentDetails(propertyId, investmentDetailObj)
            .then(
                paymentDetailVeficationRes => {
                    resolve(paymentDetailVeficationRes);
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            )
    })
}


export const generalActions = {
    getAllLocations,
    getProperty,
    getAllProperties,
    filterProperty,
    verifyPaymentDetails,
};


