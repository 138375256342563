import React, { Component } from 'react';
import { Dialog, DialogContent, Button } from '@material-ui/core';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import i18n from "../../../i18n";
import "./styles.scss";

class PaymentDetailModal extends Component {
    render() {
        return (
            <Dialog className='payment-detail-modal' fullWidth={true} maxWidth={"xs"} onClose={this.props.handleClose} open={this.props.open}>
                <DialogContent>
                    <div className="block-header"></div>
                    <div className="payment-icon"><CreditCardOutlinedIcon /></div>

                    <div className="block-content">
                        <p className="txt-type">{i18n.t('Funds withdrawal')}</p>
                        <p className="txt-status">{i18n.t('Paid')}</p>
                    </div>
                    <div className="list-item">
                        <div className="left-block">
                            <p className="txt-value">xxxx 8904</p>
                            <p className="txt-title">{i18n.t('Payment account')}</p>
                        </div>
                        <div className="right-block">
                            <p className="txt-amount">-$201.50</p>
                            <p className="txt-title">{i18n.t("6 Aug 2:15 AM")}</p>
                        </div>
                    </div>

                    <Button className="btn-print">{i18n.t('Print Receipt')}</Button>
                </DialogContent>
            </Dialog>
        )
    }
}

export default PaymentDetailModal;
